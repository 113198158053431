<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: peng wang
 * @Date: 2023-10-13 09:30:27
 * @LastEditors: peng wang
 * @LastEditTime: 2023-10-16 11:48:42
-->
<!-------------------------------------------------
description:  发票详情 申请信息
/**
 *@author xiaosanbi
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail-plus class="box-card" :labelWidth="120">
    <div slot="header" class="clearfix">
      <span>申请信息</span>
    </div>
    <ykc-detail-item-plus label="开票状态">
      {{ statusText || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="申请方">
      {{ info.applyUser || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="电费">{{ info.chargeFee || '——' }}元</ykc-detail-item-plus>
    <ykc-detail-item-plus label="服务费">{{ info.serviceFee || '——' }}元</ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票种类">
      {{ invoiceType || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票抬头">
      {{ info.invoiceTitle || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票形式">
      {{ type || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="税号">
      {{ info.taxNo || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceTyp === '2'" label="开户行名称">
      {{ info.bankName || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceTyp === '2'" label="开户账号">
      {{ info.bankCard || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceTyp === '2'" label="注册地址">
      {{ info.regAddress || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus v-if="info.invoiceTyp === '2'" label="注册电话" class="last-list">
      <span class="status infoStatus">
        {{ info.regPhone || '——' }}
      </span>
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="关联充电订单">
      <span v-if="!info.chargeRecordIds">——</span>
      <el-link v-else type="primary" @click="onRelatedHistoryOrder">查看订单</el-link>
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="关联占位订单">
      <span v-if="!info.occRecordIds">——</span>
      <el-link v-else type="primary" @click="onRelatedOccOrder">查看订单</el-link>
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="申请时间">{{ info.applyTime || '——' }}</ykc-detail-item-plus>
  </ykc-detail-plus>
</template>

<script>
  import { getNameByCode, loadDicItem } from '@/utils/dictionary';

  export default {
    name: 'ApplicationInformation',
    props: {
      info: { type: Object, default: () => ({}) },
    },
    methods: {
      // 监听相关的历史订单
      onRelatedHistoryOrder() {
        const routeUrl = this.$router.resolve({
          path: '/orderManagement/historicalOrder/List',
          query: {
            data: JSON.stringify({
              recordIds: this.info.chargeRecordIds,
            }),
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      // 监听相关的占位费订单
      onRelatedOccOrder() {
        const routeUrl = this.$router.resolve({
          path: '/orderManagement/placeholderOrder/List',
          query: {
            data: JSON.stringify({
              recordIds: this.info.occRecordIds,
            }),
          },
        });
        window.open(routeUrl.href, '_blank');
      },
    },
    data() {
      return {
        id: '',
        invoiceStatus: [],
        invoiceTypes: [],
        situationTypes: [
          {
            id: '1',
            name: '纸质发票',
          },
          {
            id: '2',
            name: '电子发票',
          },
        ],
      };
    },
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.invoiceStatus = loadDicItem('invoice_status');
      this.invoiceTypes = loadDicItem('invoice_type');
    },
    computed: {
      statusText() {
        return getNameByCode(this.info.status, this.invoiceStatus);
      },
      invoiceType() {
        return getNameByCode(this.info.invoiceTyp, this.invoiceTypes);
      },
      type() {
        return getNameByCode(this.info.invoiceSituation, this.situationTypes);
      },
    },
  };
</script>

<style scoped></style>
