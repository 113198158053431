import { render, staticRenderFns } from "./SubAgencyBillDetail.vue?vue&type=template&id=641f8eda&scoped=true&"
import script from "./SubAgencyBillDetail.vue?vue&type=script&lang=js&"
export * from "./SubAgencyBillDetail.vue?vue&type=script&lang=js&"
import style0 from "./SubAgencyBillDetail.vue?vue&type=style&index=0&id=641f8eda&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641f8eda",
  null
  
)

export default component.exports