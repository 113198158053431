<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <scroll-layout class="detail-wrap">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <ykc-detail :titles="['活动信息']" :className="'all'">
      <div slot="cvBody">
        <div class="form-detail">
          <ykc-form :label-position="'left'" label-width="68px" class="list-left">
            <ykc-detail-item label="活动名称：">
              <span>{{ dealData(detail.activityName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="承担方：">
              <span>{{ dealData(detail.activityInitiatorText) }}</span>
            </ykc-detail-item>

            <ykc-detail-item label="活动类型：">
              <span>定向发放</span>
            </ykc-detail-item>
            <ykc-detail-item label="活动状态：">
              <span>{{ statusLabel(detail.activityStatus) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="发放时间：" v-if="detail.sendType === '2'">
              <span>{{ dealData(detail.sendTime) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="发放状态：">
              <span>{{ detail.sendStatus === '1' ? '已发送' : '未发送' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="修改时间：">
              <span>{{ dealData(detail.modifiedTime) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="修改人：">
              <span>{{ dealData(detail.modifiedAccount) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="创建时间：">
              <span>{{ dealData(detail.createdTime) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="创建人：">
              <span>{{ dealData(detail.createdAccount) }}</span>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail :titles="['活动配置']" :className="'all'">
      <div slot="cvBody">
        <div class="form-detail">
          <ykc-form :label-position="'left'" label-width="110px" class="list-left">
            <ykc-detail-item label="卡券设置：">
              <ykc-table :data="detail.rewardList || []" :columns="tableColumns"></ykc-table>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail :isHead="false" :tips="false" :className="'all'">
      <div slot="cvBody">
        <ykc-table
          ref="YkcTable"
          :data="tableData"
          :columns="usersColumns"
          title="发送用户列表"
          operateFixedType="right">
          <template #headerButtons>
            <ykc-button
              type="plain"
              @click="doExport"
              v-rbac="'marketing:mcampaign:coupon:downloadlist'">
              导出
            </ykc-button>
          </template>
          <ykc-pagination
            slot="pagination"
            ref="YkcPagination"
            direct="flex-end"
            :total="userTotalCount"
            :current-page.sync="pageIndex"
            @current-change="handlePaginationCurrentChange"
            :page-size.sync="pageSize"
            @size-change="handlePaginationSizeChange"></ykc-pagination>
        </ykc-table>
      </div>
    </ykc-detail>
  </scroll-layout>
</template>

<script>
  import { couponActivity } from '@/service/apis';
  import { offlineExport } from '@/utils';

  export default {
    props: {
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        detail: {},
        tableColumns: [
          {
            label: '卡券名称',
            prop: 'rewardName',
            width: 240,
          },
          { label: '发放数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
        usersColumns: [
          {
            label: '用户编码',
            prop: 'userId',
            width: 240,
          },
          {
            label: '用户账号',
            prop: 'userAccount',
            width: 240,
          },
          {
            label: '用户类型',
            prop: 'userType',
            width: 240,
          },
          {
            label: '归属客户',
            prop: 'userOrgName',
            width: 240,
          },
        ],
        tableData: [],

        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeData: [],
        userTotalCount: 10,
        pageIndex: 1,
        pageSize: 10,
        headerButtons: [
          { text: '不可用', disabled: true },
          // handleClick字段可以执行点击处理，有该字段则不会派发 header-button-click 事件
          { text: '新增', handleClick: () => console.log('新增') },
          { text: '编辑', type: 'primary' },
          { text: '删除', type: 'text' },
          { render: () => <span style="color:red">自定义渲染</span> },
        ],
      };
    },
    computed: {},
    created() {
      this.getDetail();
    },
    methods: {
      doExport() {
        offlineExport({
          downloadType: 'marketing_export',
          jsonNode: {
            downloadKey: 'directionActivityUserList',
            activityId: this.activityId,
          },
        });
      },
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageIndex = current;
        // this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageIndex = 1;
        this.pageSize = size;
        // this.searchTableList();
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        couponActivity.orientationActivityDetail({ activityId: this.activityId }).then(res => {
          if (res) {
            this.detail = res || {};
            this.getUsers();
          }
        });
      },
      getUsers() {
        couponActivity
          .orientationActivityUsers({
            activityId: this.activityId,
            current: this.pageIndex,
            size: this.pageSize,
          })
          .then(res => {
            if (res) {
              this.tableData = res?.records || [];
              this.userTotalCount = res?.total;
            }
          });
      },
      dealData(data) {
        return data || '—';
      },
      /**
       * 获取字典
       */
      getDic(id) {
        const [property] = this.carProperty.filter(item => item.id === id);
        return property.name || '—';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .detail-wrap {
    .form-detail .ykc-detail-item-box {
      .ykc-table {
        padding: 0;
      }
    }
  }
</style>
