<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 16:48:21
 * @ Description: 商户管理>平台商户>平台商户列表  列表 新增 编辑 没有问题
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :selectable="false"
        :selectOnIndeterminate="false"
        @selection-change="selectionChange"
        :rowSelectable="row => ['1', '2'].includes(row.status)"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in tableButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="operatorId"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';
  import { operatorData } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        maxStepIndex: 1,
        selectIds: [],
        operatorId: '',
        cityList: [],
        dictionary: {},
        drawerType: '',
        willEndCount: 0,
        listType: '0',
        stepsIndex: 0,
        maxIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        tableButtons: [
          {
            label: '批量新增',
            type: 'plain',
            enabled: () => code('merchant:union:batch'),
            handler: () => {
              this.doImportFile();
            },
          },
          {
            label: '新增',
            type: 'plain',
            enabled: () => code('merchant:union:add'),
            handler: () => {
              this.add();
            },
          },
          {
            label: '导出',
            type: 'plain',
            enabled: () => code('merchant:union:export'),
            handler: () => {
              this.doExportFile();
            },
          },
        ],

        tableData: [],

        tableColumns: [
          { label: '商户编码', prop: 'operatorId', minWidth: '150px' },
          { label: '商户名称', prop: 'name', minWidth: '150px' },
          { label: '统一社会信用代码', prop: 'socialCreditCode', minWidth: '200px' },
          { label: '机构管理员', prop: 'manageName', minWidth: '150px' },
          { label: '联系方式', prop: 'contactInfo', minWidth: '150px' },
        ],
        tableTitle: '商户列表',

        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('merchant:union:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.maxStepIndex = 1;
                    this.stepsIndex = 0;
                    this.showAddDrawer = true;
                    this.isEdit = false;
                    this.operatorId = row.operatorId;
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('merchant:union:detail') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.detailLink(row);
                  }}>
                  详情
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          operatorType: '2', // 互联商户
          name: '',
          socialCreditCode: '',
          mainOrgName: '',
          manageName: '',
          contactInfo: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      this.$store
        .dispatch('getDictionaryByKeys', [
          'DISCOUNT_TYPE',
          'FLEET_TYPE',
          'DATA_STATUS',
          'OPERATOR_ATTRIBUTES',
          'ENABLE_STATE',
          'PRICE_STATUS',
        ])
        .then(res => {
          this.dictionary = res;
        });
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        return `互联商户${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '机构名称',
            placeholder: '请输入机构名称',
          },
          {
            comName: 'YkcInput',
            key: 'socialCreditCode',
            label: '统一社会信用代码',
            placeholder: '请输入统一社会信用代码',
          },
          {
            comName: 'YkcInput',
            key: 'manageName',
            label: '机构管理员',
            placeholder: '请输入机构管理员',
          },
          {
            comName: 'YkcInput',
            key: 'contactInfo',
            label: '联系方式',
            placeholder: '请输入联系方式',
          },
        ];
      },
    },
    methods: {
      /** 勾选批量停用 */
      batchStop() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择要操作的记录');
          return;
        }
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          decs: `已选中 ${this.selectIds.length}条记录，是否确认全部停用？`,
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            operatorData.batchStop({ ids: this.selectIds }).then(res => {
              console.log(res);
              this.$message.success('停用成功');
              this.searchTableList();
            });
            done();
          },
        });
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
      },
      detailLink(row) {
        this.$router.push({
          path: '/merchantCenter/merchantPlatform/Detail',
          query: {
            id: row.operatorId,
            type: 'union',
          },
        });
      },
      add() {
        this.maxStepIndex = 1;
        this.stepsIndex = 0;
        this.showAddDrawer = true;
        this.isEdit = false;
        this.operatorId = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          operatorData.userOperatorPageList({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1?.records;
          this.pageInfo.total = res1?.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExportFile2');
        offlineExport(
          {
            bizType: 'operator',
            downloadType: 'operator',
            jsonNode: {
              downloadKey: 'merchant_operator_export',
              operatorType: '2', // 互联商户
              name: this.searchParams.name,
              socialCreditCode: this.searchParams.socialCreditCode,
              mainOrgName: this.searchParams.mainOrgName,
              manageName: this.searchParams.manageName,
              contactInfo: this.searchParams.contactInfo,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.stepsIndex = 0;
        this.maxStepIndex = 0;
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form, {
          operatorType: '2',
        });
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
