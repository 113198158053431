<!--
/**
 *@author hhy 
 *@date 2023/2/7
  财务管理 对账管理 客户账单 客户账单明细
*/
-->
<template>
  <custom-list
    warm-tips=""
    :search-data="searchData"
    :search-params="searchParams"
    :show-header="true"
    table-title="资金流水"
    :table-columns="tableColumns"
    :table-operate-buttons="tableOperateButtons"
    :request-method="reconciliationApi.queryOrgBillFlowForPage"
    :page-top-buttons="pageTopButtons"
    :show-top-buttons="true"
    @clear-form="onClearFrom"
    :header-buttons="headerButtons">
    <template #headers>
      <section class="el-row--flex card-info-area">
        <div class="card-info account-card-info">
          <div class="common-text">
            <div class="account-name">{{ orgName }}</div>
          </div>
        </div>
        <div class="white-stone"></div>
        <div class="card-info purse-card-info">
          <div class="common-icon">
            <div class="big-icon"></div>
          </div>
          <div class="common-text">
            <div class="purse-text">钱包余额</div>
            <div class="purse-balance">
              <span>{{ balance }}</span>
              <span class="purse-unit">元</span>
            </div>
          </div>
        </div>
      </section>
    </template>
  </custom-list>
</template>

<script>
  import { commission, reconciliationApi } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import CustomList from '@/components/biz/CustomList.vue';
  import { localGetItem, offlineExport } from '@/utils';

  export default {
    name: 'CustomerStatementsDetail',
    components: { CustomList },
    data() {
      return {
        id: '',
        dictionary: {},
        orgName: '—',
        balance: '',
        polymerizationInfo: {},
        //  流水类型
        flowTypes: [
          { name: '后台充值', id: '1' },
          { name: '后台扣款', id: '2' },
          { name: '下级分配', id: '3' },
          { name: '下级扣回', id: '4' },
          { name: '分配至用户', id: '5' },
          { name: '用户扣回', id: '6' },
          { name: '其它', id: '7' },
        ],
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '流水号', prop: 'flowNumber', minWidth: '150px' },
          { label: '流水类型', prop: 'flowType', minWidth: '100px' },
          { label: '流水对象', prop: 'flowObject', minWidth: '100px' },
          { label: '流水时间', prop: 'createTime', minWidth: '100px' },
          { label: '流水金额', prop: 'flowAmount', minWidth: '100px' },
          { label: '操作账号', prop: 'createdNo', minWidth: '100px' },
        ],

        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          // userAccount: '',
          // userName: '',
          // phoneNumber: '',
          // userId: '',
        },
        showDrawer: false,
        isEdit: false,
        pageParams: {},
      };
    },
    created() {
      const dictionary = localGetItem('dictionary');
      if (dictionary?.customer_flow_type) {
        this.dictionary = dictionary.customer_flow_type.map(item => ({
          id: item.code,
          name: item.name,
        }));
      }
      const { orgName, orgId, billDate, billDateType } = this.$route.query;
      this.orgName = orgName;
      this.pageParams = {
        orgId,
        orgName,
        billDate,
        billDateType,
      };
      Object.assign(this.searchParams, {
        orgName,
        orgId,
        billDate,
        billDateType,
        // mainOrgId: this.mainOrgId,
      });
      this.queryHeaderInfo();
    },
    activated() {},
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'finance_export',
                  jsonNode: {
                    downloadKey: 'billFundFlowListExport',
                    ...this.searchParams,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      reconciliationApi() {
        return reconciliationApi;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'flowNumber',
            label: '流水号',
            placeholder: '请输入流水号',
          },
          {
            comName: 'YkcDropdown',
            key: 'flowType',
            label: '流水类型',
            placeholder: '请选择',
            data: this.dictionary,
          },
          {
            comName: 'YkcInput',
            key: 'flowObject',
            label: '流水对象',
            placeholder: '请输入流水对象',
          },
        ];
      },
    },
    methods: {
      onClearFrom(data) {
        console.log(data);
        this.searchParams = {
          ...data,
          ...this.pageParams,
        };
      },
      queryHeaderInfo() {
        reconciliationApi.getOrgHeader({ orgId: this.searchParams.orgId }).then(data => {
          this.balance = data.balance;
        });
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          commission.list({
            ...this.pageInfo,
            body: this.searchParams,
          }),
          commission.polymerization(this.searchParams),
        ]).then(([res1, res2]) => {
          this.tableData = res1.body;
          this.pageInfo.total = res1.totalCount || 0;
          this.polymerizationInfo = res2.body;
        });
      },
      /** 导出文件 */
      doExportFile() {
        commission.export('代理人佣金列表', this.searchParams);
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
