<!-------------------------------------------------
description:  发票详情 开票信息
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-detail-plus class="box-card" :labelWidth="120">
    <div slot="header" class="clearfix">
      <span>开票信息</span>
    </div>
    <!-- <ykc-detail-item-plus label="发票1编号">
      {{ info.invoiceNo1 || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票2编号">
      {{ info.invoiceNo2 || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="快递单号">
      {{ info.courierNumber || '——' }}
    </ykc-detail-item-plus> -->
    <!-- <ykc-detail-item-plus label="开票时间">
      {{ info.invoiceTime || '——' }}
    </ykc-detail-item-plus> -->
    <ykc-detail-item-plus label="发票流水号">
      {{ info.flowNo || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="开票时间">
      {{ info.invoiceTime || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票ofd地址">
      {{ info.ofdUrl || '——' }}
    </ykc-detail-item-plus>
    <ykc-detail-item-plus label="发票xml地址">
      {{ info.xmlUrl || '——' }}
    </ykc-detail-item-plus>
  </ykc-detail-plus>
</template>

<script>
  export default {
    name: 'BillingInformation',
    props: {
      info: { type: Object, default: () => ({}) },
    },
  };
</script>

<style scoped></style>
