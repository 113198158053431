<!-- 营销中心 平台营销工具 平台卡券管理 抵用卡管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'marketing:platform:debit:add'" @click="doAdd">
              新增
            </ykc-button>
            <ykc-button type="plain" v-rbac="'marketing:platform:debit:export'" @click="doExport">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-operation-dialog
      title="增加库存"
      :on-close="onCloseIncrease"
      :show.sync="showAddDialog"
      :before-cancel="beforeCancelIncrease"
      :before-confirm="beforeConfirmIncrease">
      <div slot="content">
        <ykc-form :model="increaseForm" :rules="increases" ref="increaseForm">
          <ykc-form-item label="当前库存数量">{{ increaseForm.stockNum }}张</ykc-form-item>
          <ykc-form-item label="调整库存数量" prop="addNum">
            <ykc-input placeholder="输入增加库存数量" maxlength="8" v-model="increaseForm.addNum">
              <div slot="append">张</div>
            </ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </ykc-operation-dialog>
    <ykc-drawer :show.sync="drawerVisible" destroy-on-close title="新增抵用卡">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body">
        <CouponAdd ref="couponAdd" />
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import CouponAdd from './CouponAdd.vue';
  import DescTxt from './DescTxt.vue';
  import { marketManage } from '@/service/apis';
  import { code, sessionGetItem, offlineExport } from '@/utils';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'platformCreditCardList',
    components: {
      CouponAdd,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'cardCouponName',
            label: '抵用卡名称',
            placeholder: '请输入抵用卡名称',
          },
          {
            comName: 'YkcInput',
            key: 'batchNumber',
            label: '抵用卡批次号',
            placeholder: '请输入抵用卡批次号',
          },
          {
            comName: 'YkcDropdown',
            key: 'useType',
            label: '抵用卡类型',
            placeholder: '请选择抵用卡类型',
            data: [
              {
                id: '1',
                name: '普通抵用卡',
              },
              {
                id: '2',
                name: '会员抵用卡',
              },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'cancelFlag',
            label: '是否作废',
            placeholder: '请选择是否作废',
            data: [
              {
                id: '0',
                name: '否',
              },
              {
                id: '1',
                name: '是',
              },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'lockActivityName',
            label: '关联活动名称',
            placeholder: '请输入关联活动名称',
          },
        ];
      },
    },
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              this.beforeCancelAdd();
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.beforeDrawerAdd();
            },
          },
        ],
        drawerVisible: false, // 新增抽屉
        increaseForm: {
          stockNum: 0,
          addNum: '',
        },
        increases: {
          addNum: [
            { required: true, message: '请输入增加库存数量', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '库存数量',
                99999999
              ),
            },
          ],
        },
        showAddDialog: false, // 增加库存弹窗
        tableData: [],

        tableColumns: [
          { label: '抵用卡批次号', prop: 'batchNumber', minWidth: '180px' },
          { label: '抵用卡名称', prop: 'cardCouponName', minWidth: '140px' },
          {
            label: '总张数',
            prop: 'stockNum',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const stockNum = row.useType === '2' ? '——' : row.stockNum;
                return (
                  <div>
                    <span title={stockNum}>{stockNum}</span>
                  </div>
                );
              },
            },
          },
          {
            label: '作废张数',
            prop: 'cancelNum',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const cancelNum = row.useType === '2' ? '——' : row.cancelNum;
                return (
                  <div>
                    <span title={cancelNum}>{cancelNum}</span>
                  </div>
                );
              },
            },
          },
          {
            label: '剩余张数',
            prop: 'remainderNum',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const remainderNum = row.useType === '2' ? '——' : row.remainderNum;
                return (
                  <div>
                    <span title={remainderNum}>{remainderNum}</span>
                  </div>
                );
              },
            },
          },
          {
            label: '是否作废',
            prop: 'cancelFlag',
            minWidth: '80px',
            scopedSlots: {
              default: ({ row }) => {
                const cancelFlagMap = {
                  0: '否',
                  1: '是',
                };
                const cancelFlag = cancelFlagMap[row.cancelFlag] || '——';
                return (
                  <div>
                    <span title={cancelFlag}>{cancelFlag}</span>
                  </div>
                );
              },
            },
          },
          {
            label: '抵用卡类型',
            prop: 'useType',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const useTypeMap = {
                  1: '普通抵用卡',
                  2: '会员抵用卡',
                };
                const useType = useTypeMap[row.useType] || '——';
                return (
                  <div>
                    <span title={useType}>{useType}</span>
                  </div>
                );
              },
            },
          },
          { label: '抵扣卡面额', prop: 'faceValue', minWidth: '80px' },
          { label: '关联活动', prop: 'lockActivityName', minWidth: '140px' },

          { label: '修改人', prop: 'operatorBy', minWidth: '140px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '140px' },
        ],
        tableTitle: '抵用卡列表',

        tableOperateButtons: [
          {
            enabled: () => code('marketing:platform:debit:store'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.useType === '2' || row.cancelFlag === '1'}
                onClick={() => {
                  this.openIncreaseInventory(row);
                }}>
                增加库存
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:platform:debit:data'),
            id: '2',
            text: '明细',
            handleClick: (btn, { row }) => {
              this.openParticulars(row);
            },
          },
          {
            enabled: () => code('marketing:platform:debit:detail'),
            id: '3',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.openDetail(row);
            },
          },
          {
            enabled: () => code('marketing:platform:debit:cancel'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.cancelFlag !== '0'}
                onClick={() => {
                  this.openCancel(row);
                }}>
                作废
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          cardCouponName: '',
          batchNumber: '',
          useType: '',
          cancelFlag: '',
          lockActivityName: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          cardCouponType: '1',
          undertakerType: '1',
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        marketManage
          .page(reqParams)
          .then(res => {
            console.log('res', res);
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        console.log('form', form);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 新增
      doAdd() {
        this.drawerVisible = true;
      },
      // 导出
      doExport() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'platform_card_export',
              cardCouponName: this.searchParams.cardCouponName || '',
              batchNumber: this.searchParams.batchNumber || '',
              useType: this.searchParams.useType || '',
              cancelFlag: this.searchParams.cancelFlag || '',
              lockActivityName: this.searchParams.lockActivityName || '',
              cardCouponType: '1',
              undertakerType: '1',
            },
          },
          this.pageInfo.total
        );
      },
      // 增加库存
      openIncreaseInventory(row) {
        this.increaseForm.cardCouponId = row.cardCouponId;
        this.increaseForm.operatorBy = row.operatorBy;
        this.increaseForm.stockNum = row.stockNum;
        this.increaseForm.addNum = '';
        this.showAddDialog = true;
      },
      // 明细
      openParticulars(row) {
        console.log('明细', row);
        this.$router.push({
          path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/particulars',
          query: {
            data: JSON.stringify(row),
          },
        });
      },
      // 详情
      openDetail(row) {
        console.log('详情', row);
        this.$router.push({
          path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/details',
          query: {
            data: JSON.stringify(row),
          },
        });
      },
      // 作废
      openCancel(row) {
        console.log('作废', row);
        this.$dialog({
          dialogType: 'feedback',
          showTitle: true,
          title: '确认作废',
          desc: DescTxt,
          descData: {
            props: {
              name: '抵用卡名称:',
              val: row.cardCouponName,
              num: `${row.remainderNum || '——'}张`,
            },
          },
          onConfirm: done => {
            marketManage
              .cancel({
                cardCouponId: row.cardCouponId,
                operatorBy: sessionGetItem('accountNo'),
                cardCouponType: '1',
                undertakerType: '1',
              })
              .then(res => {
                console.log(res);
                this.handleCancelCoupon('作废成功', true);
                this.searchTableList();
              })
              .catch(err => {
                this.handleCancelCoupon(err.data?.resultDesc, false);
              });
            done();
          },
        });
      },
      // 增加库存 取消
      beforeCancelIncrease(done) {
        this.$refs.increaseForm.clearValidate();
        done();
      },
      // 增加库存 确定
      beforeConfirmIncrease(done) {
        this.$refs.increaseForm.validate(async valid => {
          if (valid) {
            marketManage
              .stock({ ...this.increaseForm, cardCouponType: '1', undertakerType: '1' })
              .then(res => {
                console.log(res);
                this.searchTableList();
              });
            done();
          }
        });
      },
      // 关闭 增加库存弹窗
      onCloseIncrease() {
        this.$refs.increaseForm.clearValidate();
        this.showAddDialog = false;
      },

      /**
       * 作废
       */
      handleCancelCoupon(tip, status) {
        if (status) {
          this.$dialog.success(tip);
        } else {
          this.$dialog.error(tip);
        }
      },
      //  新增 取消
      beforeCancelAdd() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            this.drawerVisible = false;
          },
        });
      },
      //  新增 保存
      beforeDrawerAdd() {
        const { beforeDrawer } = this.$refs.couponAdd;
        beforeDrawer(() => {
          this.drawerVisible = false;
          this.handleSearchConfirm();
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
