<template>
  <ykc-drawer
    class="change-user-password-wrapper"
    :show.sync="showDrawer"
    destroy-on-close
    @close-drawer="resetFormData"
    :before-ensure="changePasswd">
    <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
    <template>
      <div class="drawer-main-body">
        <ykc-form ref="changeUserPasswdForm" :model="ruleForm" :rules="rules" class="el-form-wrap">
          <ykc-form-item label="原密码" prop="accountPwd">
            <ykc-input
              v-model="ruleForm.accountPwd"
              type="password"
              minlength="6"
              maxlength="16"
              placeholder="请输入原密码（6-16位的数字或字母）"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="新密码" prop="accountPwd1">
            <ykc-input
              type="password"
              v-model="ruleForm.accountPwd1"
              minlength="6"
              maxlength="16"
              placeholder="请输入新密码（6-16位的数字或字母）"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="确认密码" prop="accountPwd2">
            <ykc-input
              type="password"
              v-model="ruleForm.accountPwd2"
              minlength="6"
              maxlength="16"
              placeholder="请输入确认密码（6-16位的数字或字母）"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </template>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { updatePassword } from '@/service/apis';
  import { sessionClear, sessionGetItem } from '@/utils';

  export default {
    name: 'ChangeUserPassword',
    props: {
      showCwd: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      // 验证新旧密码不能相同
      const validateOldNewPassEquals = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'));
        } else if (value === this.ruleForm.accountPwd) {
          callback(new Error('原密码与新密码不能一致!'));
        } else {
          callback();
        }
      };
      // 验证两次输入密码要一致
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.accountPwd1) {
          callback(new Error('两次密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        // 显示弹窗
        showDrawer: false,
        ruleForm: {
          accountPwd1: '',
          accountPwd2: '',
          // 原密码
          accountPwd: '',
          //  新密码
          newPwd: '',
        },
        rules: {
          accountPwd: [
            { required: true, message: '请输入原密码', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.passwordType.regexp,
              message: regexpObj.regexp.input.passwordType.errorTips.error,
              trigger: 'blur',
            },
          ],
          accountPwd1: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.passwordType.regexp,
              message: regexpObj.regexp.input.passwordType.errorTips.error,
              trigger: 'blur',
            },
            {
              validator: validateOldNewPassEquals,
              trigger: 'blur',
            },
          ],
          accountPwd2: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.passwordType.regexp,
              message: regexpObj.regexp.input.passwordType.errorTips.error,
              trigger: 'blur',
            },
            {
              validator: validatePass2,
              trigger: 'blur',
            },
          ],
        },
      };
    },
    watch: {
      showCwd(val) {
        this.showDrawer = val;
      },
      showDrawer(val) {
        if (val) {
          this.$emit('show-change-passwd', true);
        } else {
          this.$emit('show-change-passwd', false);
        }
      },
    },
    methods: {
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {
          // 原密码
          accountPwd: '',
          //  新密码
          newPwd: '',
        };
      },
      /**
       * @desc 更新账户密码
       * */
      doUpdateAccountPasswd() {
        this.ruleForm.newPwd = this.ruleForm.accountPwd2;
        const { accountPwd: oldPassword, newPwd: newPassword } = this.ruleForm;
        const userId = sessionGetItem('accountId') || '';
        return updatePassword({
          userId,
          oldPassword,
          passWard: newPassword,
        });
      },
      /**
       * 执行更改的代码的逻辑
       * */
      changePasswd(drawerDone) {
        this.$refs.changeUserPasswdForm.validate(valid => {
          if (valid) {
            this.doUpdateAccountPasswd()
              .then(() => {
                // 提示成功
                this.$message.success('更新账户密码成功');
                // 关闭抽屉
                drawerDone();
                // 重置表单
                this.resetFormData();
                sessionClear();
                window.open('/login', '_self');
              })
              .catch(err => {
                console.error('error:', err);
              });
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .el-form.ykc-form.el-form-wrap {
    padding-left: 40px;
  }
</style>
