<template>
  <el-col v-bind="$attrs">
    <div class="detail-item" :style="itemStyle">
      <div :style="labelStyle">
        {{ labelStr }}
      </div>
      <slot></slot>
    </div>
  </el-col>
</template>

<script>
  export default {
    name: 'YkcDetailItemPlus',
    inject: ['rootLabelWidth', 'rootShowColon', 'rootAlign'],
    props: {
      label: String,
      labelWidth: {
        type: Number,
      },
      vAlign: {
        type: String,
        default: 'center',
      },
      align: {
        type: String,
      },
    },
    computed: {
      labelStyle() {
        return {
          color: '#9b9b9b',
          padding: '0 5px',
          width: `${this.labelWidth || this.rootLabelWidth}px`,
          textAlign: this.align || this.rootAlign || 'left',
          minWidth: `${this.labelWidth || this.rootLabelWidth}px`,
        };
      },
      itemStyle() {
        return {
          alignItems: this.vAlign,
        };
      },
      labelStr() {
        return `${this.label}${this.showColon ?? this.rootShowColon ? ':' : ''}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .detail-item {
    font-size: 12px;
    color: '#000';
    padding: 0 8px 8px 0;
    line-height: inherit;
    display: flex;
    align-items: center;
  }
</style>
