<!-------------------------------------------------
description:  运维中心 设备运维 设备报文
/**
 *@author xiaosanye 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      warm-tips="设备报文查询只支持7天日志报文查询，如有需要请定期导出保存"
      :search-data="searchData"
      :search-params="searchParams"
      :show-header="true"
      table-title="报文列表"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons"
      :request-method="omManagementApi.maintenanceQuery"
      :header-buttons="headerButtons.filter(item => item.enabled())" />
  </div>
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { omManagementApi } from '@/service/apis';
  import { offlineExport, code, getLastTime } from '@/utils';

  export default {
    name: 'DeviceMessageList',
    components: { CustomList },
    data() {
      return {
        omManagementApi,
        searchParams: {
          timePeriod: [getLastTime('yyyy-MM-dd', 7), getLastTime('yyyy-MM-dd', 1)],
          startTime: getLastTime('yyyy-MM-dd', 7),
          endTime: getLastTime('yyyy-MM-dd', 1),
          // userAccount: '',
          // stationName: '',
          // status: '',
        },
        //  0= 发送方=平台 接收方=充电设备  1= 发送方=充电设备 接收方=平台
        senderDictionary: [
          { name: '平台', id: 0 },
          { name: '充电设备', id: 1 },
        ],
        receiverDictionary: [
          { name: '充电设备', id: 0 },
          { name: '平台', id: 1 },
        ],
        tableColumns: [
          { label: '终端编码', prop: 'gunNo', scopedSlots: { defaultTitle: () => '' } },
          { label: '报文指令', prop: 'type', scopedSlots: { defaultTitle: () => '' } },
          { label: '事件时间', prop: 'actionTime', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '发送方',
            // prop: 'upDown',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { upDown } = row;
                const label =
                  this.senderDictionary.find(item => Number(item.id) === Number(upDown))?.name ??
                  '—';
                return <span>{label}</span>;
              },
            },
          },
          {
            label: '接收方',
            // prop: 'upDown',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { upDown } = row;
                const label =
                  this.receiverDictionary.find(item => Number(item.id) === Number(upDown))?.name ??
                  '—';
                return <span>{label}</span>;
              },
            },
          },
          { label: '客户端ip', prop: 'clientIp', scopedSlots: { defaultTitle: () => '' } },
          { label: '服务端ip', prop: 'severIp', scopedSlots: { defaultTitle: () => '' } },
          // {
          //   label: '限制站点',
          //   prop: 'stationInfoVOList',
          //   title: false,
          //   scopedSlots: {
          //     defaultTitle: () => '',
          //     default: ({ row }) => {
          //       const { stationInfoVOList } = row;
          //       const reducer = (accumulator, currentValue) => `${accumulator} ${currentValue}`;
          //       const labels = stationInfoVOList?.map(item => item.stationName).reduce(reducer);
          //       return (
          //         <el-tooltip effect="dark" content={labels} placement="top-start">
          //           <span>{labels}</span>
          //         </el-tooltip>
          //       );
          //     },
          //   },
          // },
          // {
          //   label: '状态',
          //   prop: 'status',
          //   scopedSlots: {
          //     defaultTitle: () => '',
          //     default: ({ row }) => {
          //       const title =
          //         this.dictionary?.find(o => Number(o.id) === Number(row.status))?.name ?? '——';
          //       return <span>{title}</span>;
          //     },
          //   },
          // },
        ],
        tableOperateButtons: [
          // {
          //   render: (h, button, { row }) => {
          //     return (
          //       <ykc-button
          //         type="text"
          //         class="do-enable-btn"
          //         onClick={() => {
          //           // this.doEdit(row);
          //           this.currentRow = { ...row };
          //           this.show2 = true;
          //         }}>
          //         编辑
          //       </ykc-button>
          //     );
          //   },
          // },
          // {
          //   render: (h, button, { row }) => {
          //     return (
          //       <ykc-button
          //         type="text"
          //         class="do-enable-btn"
          //         onClick={() => {
          //           this.gotoDetailPage(row);
          //         }}>
          //         详情
          //       </ykc-button>
          //     );
          //   },
          // },
          // {
          //   render: (h, button, { row }) => {
          //     // console.log('stop start btn row:', row);
          //     const oppositeTips = row.status === 1 ? '停用' : '启用';
          //     return (
          //       <ykc-button
          //         type="text"
          //         class="do-enable-btn"
          //         onClick={() => {
          //           this.doStartOrStop(row);
          //         }}>
          //         {oppositeTips}
          //       </ykc-button>
          //     );
          //   },
          // },
        ],
      };
    },
    methods: {
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
        this.searchParams.startTime = event?.date[0];
        this.searchParams.endTime = event?.date[1];
      },
    },
    computed: {
      headerButtons() {
        return [
          // {
          //   label: '通知规则',
          //   handle: (data, ctx) => {
          //     console.log('-> data', data);
          //     console.log('-> ctx', ctx);
          //   },
          // },
          // {
          //   label: '规则配置',
          //   handle: (data, ctx) => {
          //     console.log('-> data', data);
          //     console.log('-> ctx', ctx);
          //   },
          // },
          {
            enabled: () => code('maintain:alarm:msg:export'),
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'message',
                  jsonNode: {
                    downloadKey: 'message',
                    ...this.searchParams,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        // const all = { id: '', name: '全部' };
        console.log(getLastTime('yyyy-MM-dd', 7));
        return [
          {
            comName: 'YkcDatePicker',
            isDateType: false,
            key: 'timePeriod',
            change: this.handleChange,
            limitDate: getLastTime('yyyy-MM-dd', 8),
            label: '时间周期',
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'type',
            label: '报文指令',
            placeholder: '请输入报文指令',
          },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '充电站',
          //   placeholder: '请输入充电站',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警事项',
          //   placeholder: '请输入告警事项',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警码',
          //   placeholder: '请输入告警码',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '告警时间',
          //   placeholder: '请输入告警时间',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'stationName',
          //   label: '平台响应动作',
          //   placeholder: '请输入平台响应动作',
          // },
          // {
          //   comName: 'YkcDropdown',
          //   key: 'status',
          //   label: '状态',
          //   placeholder: '请选择状态',
          //   data: [...this.dictionary],
          // },
        ];
      },
    },
  };
</script>

<style scoped></style>
