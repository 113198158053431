<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-11
 * @Description: 营销管理/平台营销工具/平台价格活动/详情
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <div class="flexConent">
        <ykc-detail :titles="['活动基本信息']" :className="'left'">
          <div slot="cvBody" class="flex">
            <div class="flex-base">
              <ykc-form :label-position="'left'">
                <ykc-detail-item :label="'创建人:'">
                  <span>{{ formatData(detail.createdAccount) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'活动名称:'">
                  <span style="margin-right: 10px">{{ formatData(detail.activityName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'活动状态:'">
                  <span>{{ statusLabel(detail.status) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'活动时间:'">
                  <span>{{ formatData(detail.startTime) }}~ {{ formatData(detail.endTime) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'活动周天:'">
                  <div>
                    <span class="week-item" v-for="(item, index) in weekDatas" :key="index">
                      {{ item.label }}
                    </span>
                  </div>
                </ykc-detail-item>
              </ykc-form>
            </div>
            <div class="flex-base">
              <ykc-form :label-position="'left'">
                <ykc-detail-item :label="'修改人:'">
                  <span>{{ formatData(detail.modifiedAccount) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'创建时间:'">
                  <span>{{ formatData(detail.createdTime) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'修改时间:'">
                  <span>{{ formatData(detail.modifiedTime) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'活动说明:'">
                  <ykc-input
                    maxlength="500"
                    type="textarea"
                    placeholder="活动说明"
                    v-model="detail.remarks"></ykc-input>
                </ykc-detail-item>
              </ykc-form>
            </div>
          </div>
        </ykc-detail>
        <ykc-detail :titles="['活动优惠信息']" :className="'right'">
          <div slot="cvBody" class="flex">
            <div class="flex-base discount-item-enable">
              <div class="top-title">
                {{ detail.powerRateType === '1' ? '一口价' : '折扣' }}优惠
              </div>
              <div style="margin-top: 24px">{{ electPrice() }}</div>
              <div class="bottom-tip">电费活动价</div>
            </div>
            <div class="flex-base discount-item-enable" style="margin-left: 16px">
              <div class="top-title">
                {{ detail.serviceRateType === '1' ? '一口价' : '折扣' }}优惠
              </div>
              <div style="margin-top: 24px">{{ servicePrice() }}</div>
              <div class="bottom-tip">服务费活动价</div>
            </div>
            <div class="flex-base discount-item-enable" style="margin-left: 16px">
              <div class="top-title">叠加会员折扣</div>
              <div style="margin-top: 24px">
                {{ detail.superposeMemberDiscount === '1' ? '是' : '否' }}
              </div>
              <div class="bottom-tip">叠加会员折扣</div>
            </div>
          </div>
        </ykc-detail>
      </div>
      <div class="onlineConent" style="margin-top: 12px">
        <ykc-detail :isHead="false" :tips="false" :className="'all'">
          <div slot="cvBody">
            <ykc-tabs
              headerButtonText="导出"
              :tabList="tabList"
              :active="activeName"
              :rightHeaderNoMarginTop="true"
              :showHeaderButtons="showDownloadBtn"
              @btnClick="clickExport">
              <div slot="first">
                <ykc-table ref="YkcTable" :data="firstTableData" :columns="firstTableColumns">
                  <ykc-pagination
                    slot="pagination"
                    ref="YkcPagination"
                    :total="stationPageTotal"
                    :page-size.sync="stationPageSize"
                    :current-page.sync="stationPageIndex"
                    @current-change="handleStationPaginationCurrentChange"
                    @size-change="handleStationPaginationSizeChange" />
                </ykc-table>
              </div>
              <div slot="second">
                <ykc-table ref="YkcTable" :data="secondTableData" :columns="secondTableColumns">
                  <ykc-pagination
                    slot="pagination"
                    ref="YkcPaginationUser"
                    :total="userPageTotal"
                    :current-page.sync="userPageIndex"
                    @current-change="handleUserPaginationCurrentChange"
                    :page-size.sync="userPageSize"
                    @size-change="handleUserPaginationSizeChange" />
                </ykc-table>
              </div>
            </ykc-tabs>
          </div>
        </ykc-detail>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { platformPriceActivity } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    data() {
      return {
        id: '',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tabList: [
          {
            label: '活动站点列表',
            name: 'first',
          },
          {
            label: '活动用户列表',
            name: 'second',
          },
        ],
        activeName: 'first',
        firstTableData: [],
        firstTableColumns: [],
        secondTableData: [],
        secondTableColumns: [],
        detail: {},
        weekDatas: [],
        allWeekDatas: [
          {
            value: '1',
            label: '星期一',
          },
          {
            value: '2',
            label: '星期二',
          },
          {
            value: '3',
            label: '星期三',
          },
          {
            value: '4',
            label: '星期四',
          },
          {
            value: '5',
            label: '星期五',
          },
          {
            value: '6',
            label: '星期六',
          },
          {
            value: '7',
            label: '星期日',
          },
        ],
        disableWeeks: ['0', '1', '2', '3', '4', '5', '6', '7'],
        weekProps: {
          label: 'label',
          value: 'value',
        },
        selectCheckbox: ['1'],
        // 分页
        userPageIndex: 1,
        userPageSize: 10,
        userPageTotal: 0,
        stationPageIndex: 1,
        stationPageSize: 10,
        stationPageTotal: 0,
        showDownloadBtn: false,
      };
    },
    computed: {},
    created() {
      const { activityId } = this.$route.query;
      this.id = activityId;
      console.log('this.$route', this.$route);
      this.showDownloadBtn = code('marketing:platform:price:detail:download');
      this.getDetail();
    },
    methods: {
      weekIndex2Label(index) {
        let label = '';
        switch (index) {
          case '1':
            label = '一';
            break;
          case '2':
            label = '二';
            break;
          case '3':
            label = '三';
            break;
          case '4':
            label = '四';
            break;
          case '5':
            label = '五';
            break;
          case '6':
            label = '六';
            break;
          case '7':
            label = '七';
            break;
          default:
            break;
        }
        return label;
      },
      clickExport(value) {
        if (value === 'first') {
          if (this.detail.stationRange === '1') {
            // 电站列表
            offlineExport({
              downloadType: 'marketing_export',
              jsonNode: {
                downloadKey: 'platform_activity_station_list',
                activityId: this.id,
              },
            });
          } else {
            // 电站分组
            offlineExport({
              downloadType: 'marketing_export',
              jsonNode: {
                downloadKey: 'platform_activity_group_list',
                activityId: this.id,
                groupType: '1',
              },
            });
          }
        } else if (this.detail.userRange === '1') {
          // 用户
          offlineExport({
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'platform_activity_org_list',
              activityId: this.id,
            },
          });
        } else {
          // 分组
          offlineExport({
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'platform_activity_group_list',
              activityId: this.id,
              groupType: '2',
            },
          });
        }
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        platformPriceActivity.detail({ activityId: this.id }).then(res => {
          this.detail = res || {};
          this.formatWeekDays();
          // 电站维度
          if (this.detail.stationRange === '1') {
            this.firstTableColumns = [
              { label: '电站编码', prop: 'stationId', minWidth: '150px' },
              { label: '电站名称', prop: 'stationName', minWidth: '150px' },
              { label: '电站类型', prop: 'stationTypeName', minWidth: '150px' },
              { label: '归属商户', prop: 'stationOperatorName', minWidth: '150px' },
            ];
            // 商户维度，调用电站分页查询
          } else {
            // 电站分组
            this.firstTableColumns = [
              { label: '电站分组', prop: 'groupName', minWidth: '150px' },
              { label: '电站数量', prop: 'count', minWidth: '150px' },
            ];
          }
          this.getStationDomainData();
          // 用户维度
          if (this.detail.userRange === '1') {
            // 归属商户 调用用户分页查询
            this.secondTableColumns = [
              { label: '客户编码', prop: 'orgId', minWidth: '150px' },
              { label: '客户名称', prop: 'orgName', minWidth: '150px' },
              { label: '客户类型', prop: 'orgType', minWidth: '150px' },
            ];
          } else {
            // 用户分组，调用分组接口
            this.secondTableColumns = [
              { label: '用户分组', prop: 'groupName', minWidth: '150px' },
              { label: '用户数量', prop: 'count', minWidth: '150px' },
            ];
          }
          this.getUserDomainData();
        });
      },
      formatWeekDays() {
        this.weekDatas = [];
        if (this.detail.activityWeek) {
          const weeks = this.detail.activityWeek.split(',');
          this.allWeekDatas.forEach(item => {
            if (weeks.indexOf(item.value) > -1) {
              this.weekDatas.push(item);
            }
          });
        }
      },
      formatData(data) {
        return data || '——';
      },
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      dicStatus() {
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已停止' },
          { id: '4', name: '已到期' },
        ];
      },
      electPrice() {
        if (!this.detail.powerRate) {
          return '——';
        }
        return `${this.detail.powerRate}${this.detail.powerRateType === '1' ? '元/度' : '%'}`;
      },
      servicePrice() {
        if (!this.detail.serviceRate) {
          return '——';
        }
        return `${this.detail.serviceRate}${this.detail.serviceRateType === '1' ? '元/度' : '%'}`;
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handleStationPaginationCurrentChange(current) {
        this.stationPageIndex = current;
        this.getStationDomainData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handleStationPaginationSizeChange(size) {
        this.stationPageSize = size;
        this.stationPageIndex = 1;
        this.getStationDomainData();
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handleUserPaginationCurrentChange(current) {
        this.userPageIndex = current;
        this.getUserDomainData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handleUserPaginationSizeChange(size) {
        this.userPageSize = size;
        this.userPageIndex = 1;
        this.getUserDomainData();
      },
      getStationDomainData() {
        if (this.detail.stationRange === '1') {
          // 商户维度，调用电站分页查询
          platformPriceActivity
            .detailStations({
              activityId: this.id,
              current: this.stationPageIndex,
              size: this.stationPageSize,
            })
            .then(res => {
              this.firstTableData = res?.records || [];
              this.stationPageTotal = res?.total;
            });
        } else {
          // 电站分组
          platformPriceActivity
            .detailUserOrStationGroups({
              activityId: this.id,
              groupType: '1',
              current: this.stationPageIndex,
              size: this.stationPageSize,
            })
            .then(res => {
              this.firstTableData = res?.records || [];
              this.stationPageTotal = res?.total;
            });
        }
      },
      getUserDomainData() {
        if (this.detail.userRange === '1') {
          // 归属商户 调用用户分页查询
          platformPriceActivity
            .detailUsers({
              activityId: this.id,
              current: this.userPageIndex,
              size: this.userPageSize,
            })
            .then(res => {
              this.secondTableData = res?.records || [];
              this.userPageTotal = res?.total;
            });
        } else {
          // 用户分组，调用分组接口
          platformPriceActivity
            .detailUserOrStationGroups({
              activityId: this.id,
              groupType: '2',
              current: this.userPageIndex,
              size: this.userPageSize,
            })
            .then(res => {
              this.secondTableData = res?.records || [];
              this.userPageTotal = res?.total;
            });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 10px;
    display: flex;
    flex-flow: column;

    .week-data {
      min-width: 52px;
      height: 26px;
      line-height: 26px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 2px;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      word-break: break-word;
      color: #4d8cfd;
    }

    .box-card {
      margin: 5px 0;

      .clearfix {
        height: 19px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .title {
      }

      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;

        th {
          height: 44px;
        }

        td {
          height: 40px;
        }

        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }

      .title {
        padding-left: 15px;
        position: relative;

        &::before {
          content: ' ';
          position: absolute;
          width: 8px;
          height: 8px;
          left: 0;
          top: calc(50% - 4px);
          border-radius: 8px;
          background-color: var(--theme-color-primary);
        }
      }
    }

    .discount-item-enable {
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.08);
      color: #4d8cfd;
      border: 2px solid #4d8cfd;
      height: 153px;
      font-weight: 600;
      font-size: 24px;
      flex: 1;
      text-align: center;

      .top-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #4d8cfd;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }

      .bottom-tip {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        margin-top: 14px;
        color: #000000;
        line-height: 17px;
      }
    }

    .discount-item-disable {
      background: rgba(255, 255, 255, 0.08);
      border: 2px solid rgba(0, 0, 0, 0.5);
      flex: 1;
      border-radius: 4px;
      height: 153px;
      color: #4d8cfd;
      font-size: 24px;
      text-align: center;
      font-weight: 600;

      .top-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        background: #4d8cfd;
      }

      .bottom-tip {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        margin-top: 14px;
        line-height: 17px;
      }
    }

    .flex-base {
      flex: 1;
    }
    .week-item {
      background: rgba(77, 140, 253, 0.1);
      display: inline-block;
      margin-right: 4px;
      padding: 4px 6px;
      text-align: center;
      font-size: 11px;
      color: #4d8cfd;
      border-radius: 2px;
      margin-bottom: 4px;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }
</style>
