<!--
 * @Author: wang peng
 * @Date: 2023-2-28
 * @Description: 订单管理-实时订单-订单详情
-->
<template>
  <scroll-layout class="real-time-detail">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="flexConent">
      <ykc-detail
        :titles="[`订单号：${dealData(detailData.tradeSeq)}`]"
        :tips="tips"
        :tags="tags"
        :isLine="true"
        :className="'left'">
        <div slot="cvBody">
          <div class="form-detail flex">
            <ykc-form :label-position="'left'" label-width="68px" class="list-left">
              <ykc-detail-item :label="'充电用户：'">
                <span>{{ dealData(detailData.userAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'用户姓名：'">
                <span>{{ dealData(detailData.nickName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'充电卡号：'">
                <span>{{ dealData(detailData.cardNo) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'机构名称：'">
                <span>{{ dealData(detailData.companyName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'车牌号码：'">
                <span>{{ dealData(detailData.plateNumber) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电站商户：'">
                <span>{{ dealData(detailData.stationOperatorName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'充电场站:'">
                <span>{{ dealData(detailData.stationName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'城市：'">
                <span>{{ dealData(detailData.address) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'终端名称：'">
                <span>{{ dealData(detailData.name) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'终端编码：'">
                <span>{{ dealData(detailData.gunCode) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电桩类型：'">
                <span>{{ dealData(detailData.pileType) == 1 ? '直流' : '交流' }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'电桩功率：'" class="last-list">
                <span>{{ dealData(detailData.pilePower) }}KW</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <div class="right-boxs">
        <ykc-detail :isHead="false" :tips="false">
          <div slot="cvBody">
            <div class="card-content">
              <div class="card">
                <div class="date">
                  充电时长:&nbsp;&nbsp;{{ dealData(detailData?.chargedTimeOri) }}分钟
                </div>
                <div class="time-content">
                  <div class="time-num">
                    <span>
                      {{ dealData(detailData.chargedPower) }}
                    </span>
                  </div>
                  <div class="time-title">实时功率 (KW)</div>
                </div>
                <ykc-icon name="chargingTime"></ykc-icon>
              </div>
              <div class="card">
                <div class="time-content2">
                  <div class="time-num">
                    {{ dealData(detailData.soc) }}
                  </div>
                  <div class="time-title">SOC (%)</div>
                </div>
                <ykc-icon name="charging-degree"></ykc-icon>
              </div>
              <div class="card">
                <div class="time-content2">
                  <div class="time-num">
                    {{ dealData(detailData.chargedPowerOri) }}
                  </div>
                  <div class="time-title">充电电量 (kw·h)</div>
                </div>
                <ykc-icon name="amount"></ykc-icon>
              </div>
            </div>
          </div>
        </ykc-detail>
        <ykc-detail
          :titles="[`其他信息`]"
          :tips="tips"
          :isLine="true"
          :className="'left cardview-box-content'">
          <div slot="cvBody">
            <div class="form-detail flex">
              <ykc-form :label-position="'left'" label-width="80px" class="list-left">
                <ykc-detail-item :label="'开始SOC：'">
                  <span>{{ dealData(detailData.startSoc) }}%</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'充电模式：'">
                  <span>{{ dealData(detailData.multipleChargeName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'启动时长：'">
                  <span>{{ dealData(detailData.startChargedTime) }}s</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'识别Vin：'">
                  <span>{{ dealData(detailData.vinCode) }}</span>
                </ykc-detail-item>
              </ykc-form>
              <ykc-form :label-position="'left'" label-width="95px" class="list-left">
                <ykc-detail-item :label="'绑定Vin：'">
                  <span>{{ dealData(detailData.carVinCode) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'流量互联单号：'">
                  <span>{{ dealData(detailData.zdlTradeSeq) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'桩联互联单号：'">
                  <span>{{ dealData(detailData.pileTradeSeq) }}</span>
                </ykc-detail-item>
              </ykc-form>
            </div>
          </div>
        </ykc-detail>
      </div>
    </div>
    <share-chart
      :title="'实时状态监测'"
      :page="'historicalDetail'"
      :tradeNo="tradeNo"></share-chart>
  </scroll-layout>
</template>

<script>
  import shareChart from '../OrderShareChart.vue';
  import { orderPage } from '@/service/apis';

  export default {
    name: 'realTimeDetail',
    components: {
      shareChart,
    },
    data() {
      return {
        tips: false,
        tradeNo: null,
        detailData: {},
        tags: [],
        chargedTimeStr: [],
        chargedHourStr: [],
        titles: ['订单号', '118298292992'],
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      this.tradeNo = this.$route.query.tradeNo;
      console.log('this.$route', this.$route.query);
      this.getDetail(this.$route.query.recordId);
    },
    methods: {
      /**
       * 获取详情数据
       */
      getDetail(recordId) {
        orderPage
          .chargingOrderInfo({ recordId })
          .then(res => {
            console.log('请求详情数据+++++', res);
            this.detailData = res;

            const cls = [
              { id: 1, name: 'APP启动' },
              { id: 2, name: '小程序启动' },
              { id: 3, name: '刷卡充电' },
              { id: 4, name: '即插即充' },
              { id: 5, name: '流量方' },
            ][Number(this.detailData?.chargingSource) - 1];

            this.tags = [
              {
                type: 'tt2',
                name: `${cls?.name || '一'}`,
              },
              {
                type: 'tt2',
                name: `${this.detailData.startTime} 开始充电`,
              },
            ];
          })
          .catch(err => {
            console.log(err);
          });
      },
      dealData(data) {
        return data || '——';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .real-time-detail {
    .flexConent {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .cardview-body {
        .bottom-content {
          .el-scrollbar__wrap {
            .el-scrollbar__view {
              .list {
                min-width: 200px !important;
              }
            }
          }
        }
      }
      .right-boxs {
        margin: 0 10px;
        flex: 1;
        .cardview-box-content {
          width: calc(97.4%);
          margin-top: 10px;
        }
      }
    }

    .button-group {
      margin-bottom: 10px;

      .ykc-button {
        margin-left: 8px;
      }
    }

    .last-list {
      .el-form-item {
        margin-bottom: 0;
      }
    }

    .cardview-box {
      .cardview-head {
        margin-top: 0px;
        padding-left: 10px;

        .cardview-head-box {
          margin: 17px 0;
        }

        .cardview-body {
          margin: -10px;
        }
      }

      .table-body .cardview-body {
        padding: 0 0 20px 0;
        margin: 0 -10px;
      }
    }

    .card-content {
      display: flex;

      .card {
        width: 32%;
        height: 187px;
        margin-right: 20px;
        border: 1px solid #ebebf0;
        border-radius: 2px;
        overflow: hidden;
        position: relative;

        .date {
          width: auto;
          display: inline-block;
          font-family: PingFangSC-Medium;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: -0.17px;
          text-align: left;
          background: #3773fc;
          border-bottom-right-radius: 10px;
          padding: 4px 10px;
        }

        .time-content {
          letter-spacing: 0;
          text-align: center;
          margin-top: 38px;

          .time-num {
            font-size: 28px;
            color: #3773fc;

            .unit {
              font-size: 14px;
            }
          }

          .time-title {
            font-size: 14px;
            color: #000000;
            margin-top: 18px;
          }
        }

        .time-content2 {
          letter-spacing: 0;
          text-align: center;
          margin-top: 56px;

          .time-num {
            font-size: 28px;
            color: #3773fc;

            .unit {
              font-size: 14px;
            }
          }

          .time-title {
            font-size: 14px;
            color: #000000;
            margin-top: 18px;
          }
        }
      }

      .card:last-child {
        margin-right: 0;
      }
    }

    .ykc-icon-power:before,
    .ykc-icon-chargingTime:before,
    .ykc-icon-soc:before,
    .ykc-icon-chargingTime:before,
    .ykc-icon-charging-degree:before {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 72px;
      color: rgb(55, 115, 252);
      opacity: 0.096;
    }

    .ykc-icon-amount:before {
      position: absolute;
      bottom: 0;
      right: -2px;
      font-size: 72px;
      color: rgb(55, 115, 252);
      opacity: 0.096;
    }

    .right {
      .cardview-body {
        padding: 20px 10px;
      }
    }
  }
</style>
