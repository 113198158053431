<!--
/**
 *@author hhy 
 *@date 2023/2/7
 财务管理 账号管理 客户司机账户详情
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <section class="el-row--flex card-info-area">
        <div class="card-info account-card-info">
          <div class="common-icon">
            <div class="big-icon"></div>
          </div>
          <div class="common-text">
            <div class="account-name">{{ headerInfo.userAccount || '—' }}</div>
            <div class="user-name">
              <span class="common-label-key">归属客户：</span>
              <span class="common-label-val">{{ headerInfo.orgName || '—' }}</span>
            </div>
            <div class="user-name">
              <span class="common-label-key">用户姓名：</span>
              <span class="common-label-val">{{ headerInfo.nickName || '—' }}</span>
            </div>
            <div class="user-name">
              <span class="common-label-key">手机号：</span>
              <span class="common-label-val">{{ headerInfo.userPhone || '—' }}</span>
            </div>
          </div>
        </div>
        <div class="white-stone"></div>
        <div class="card-info purse-card-info">
          <div class="common-icon">
            <div class="big-icon"></div>
          </div>
          <div class="common-text">
            <div class="purse-text">企业钱包</div>
            <div class="purse-balance">
              <span>{{ headerInfo.balance || '—' }}</span>
              <span class="purse-unit">元</span>
            </div>
          </div>
        </div>
      </section>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="资金流水"
        operateFixedType="right"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import ScrollLayout from '@/layouts/ScrollLayout.vue';
  import YkcPagination from '@/components/ykc-ui/pagination/src/main.vue';
  import YkcSearch from '@/components/ykc-ui/search/src/ykc-search.vue';
  import YkcButton from '@/components/ykc-ui/button/src/ykc-button.vue';
  import YkcTable from '@/components/ykc-ui/table/src/table.vue';
  import { CustomDriverAccounts } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { getLastTime, offlineExport, localGetItem, code } from '@/utils';

  export default {
    name: 'CustomDriverAccountsDetail',
    components: { YkcPagination, YkcSearch, YkcButton, YkcTable, ScrollLayout },
    data() {
      return {
        id: '',
        dictionary: {},
        headerInfo: {},
        //  流水类型
        flowTypes: [
          { name: '充电消费', id: '1' },
          { name: '占位费扣款', id: '2' },
          { name: '占位费退款', id: '3' },
          { name: '上级分配', id: '4' },
          { name: '上级扣回', id: '5' },
          { name: '退款', id: '6' },
          { name: '其它', id: '7' },
        ],
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            enabled: () => code('finance:account:driver:export'),
            id: '1',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '流水号', prop: 'flowNumber', minWidth: '100px' },
          { label: '流水类型', prop: 'flowTypeStr', minWidth: '100px' },
          { label: '流水对象', prop: 'flowObject', minWidth: '100px' },
          { label: '流水时间', prop: 'flowDate', minWidth: '100px' },
          { label: '流水金额', prop: 'flowAmount', minWidth: '100px' },
        ],

        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          flowNumber: '',
          flowType: '',
          flowObject: '',
          flowDate: [getLastTime('yyyy-MM-dd', 30), getLastTime('yyyy-MM-dd', 0)],
          userId: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const dictionary = localGetItem('dictionary');
      const enabledKeys = ['5', '6', '7', '8', '9'];
      if (dictionary?.user_flow_type) {
        this.dictionary = dictionary.user_flow_type
          .filter(item => enabledKeys.includes(item.code))
          .map(item => ({
            id: item.code,
            name: item.name,
          }));
      }
      const { id } = this.$route.query;
      this.id = id;
      this.searchParams.userId = id;
      this.queryHeaderInfo();
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            isDateType: false,
            key: 'flowDate',
            label: '流水时间',
            clearable: true,
          },
          {
            comName: 'YkcInput',
            key: 'flowNumber',
            label: '流水号',
            placeholder: '请输入流水号',
          },
          {
            comName: 'YkcDropdown',
            key: 'flowType',
            label: '流水类型',
            placeholder: '请选择',
            data: this.dictionary,
          },
          {
            comName: 'YkcInput',
            key: 'flowObject',
            label: '流水对象',
            placeholder: '请输入流水对象',
          },
        ];
      },
    },
    methods: {
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /**
       * @function queryHeaderInfo
       * @desc 查询头部新消息
       * */
      queryHeaderInfo() {
        CustomDriverAccounts.detailHeaderInfo({ userId: this.id }).then(res => {
          this.headerInfo = res || {};
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const customDriverAccountsDetailReqParams = {
          ...this.searchParams,
          flowTimeStart: this.searchParams.flowDate[0],
          flowTimeEnd: this.searchParams.flowDate[1],
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        delete customDriverAccountsDetailReqParams.flowDate;
        CustomDriverAccounts.flowList(customDriverAccountsDetailReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'finance_export',
            jsonNode: {
              downloadKey: 'clientDriverFlowList',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        // Object.assign(this.searchParams, form);
        this.searchParams = { ...this.searchParams, ...form, userId: this.id };
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
