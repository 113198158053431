<!--
/**
 *@author hhy
 *@date 2023年2月9日
 财务管理 对账管理 客户账单 子机构账单
*/
-->
<template>
  <custom-list
    warm-tips=""
    :search-data="searchData"
    :search-params="searchParams"
    :show-header="true"
    table-title="子机构账单"
    :table-columns="tableColumns"
    :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
    :request-method="reconciliationApi.queryChildFundBillForPage"
    :page-top-buttons="pageTopButtons"
    :show-top-buttons="true"
    :header-buttons="headerButtons.filter(item => item.enabled())">
    <template #headers>
      <section class="el-row--flex card-info-area">
        <div class="card-info purse-card-info">
          <div class="common-icon">
            <div class="big-icon enterprise-icon"></div>
          </div>
          <div class="common-text">
            <!-------------------------------------------------
            description:
            王振宇(王振宇)@2月15日 16:39@方显飞(方显飞)账户管理 - 内部客户账户详情和客户账户详情，上级客户展示——
对账管理 - 子机构账单列表 - 上方的子机构名称、主机构两个字段删除
            --------------------------------------------------->
            <div class="user-name">
              <span class="common-label-key">上级机构：</span>
              <span class="common-label-val">{{ orgName }}</span>
            </div>
          </div>
        </div>
      </section>
    </template>
  </custom-list>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import CustomList from '@/components/biz/CustomList.vue';
  import { offlineExport, code } from '@/utils';
  import { commission, reconciliationApi } from '@/service/apis';

  export default {
    name: 'SubAgencyBill',
    components: { CustomList },
    data() {
      return {
        orgName: '—',
        mainOrgId: '—',
        billDate: '—',
        billDateType: '—',
        flowTypes: [
          { name: '充电消费', id: '1' },
          { name: '占位费扣款', id: '2' },
          { name: '占位费退款', id: '3' },
          { name: '后台充值', id: '4' },
          { name: '后台扣款', id: '5' },
          { name: '上级分配', id: '6' },
          { name: '上级扣回', id: '7' },
          { name: '其它', id: '8' },
        ],
        id: '',
        dictionary: {},
        polymerizationInfo: {},
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '账单时间', prop: 'billDate', minWidth: '100px' },
          { label: '客户名称', prop: 'orgName', minWidth: '100px' },
          { label: '期初金额', prop: 'initialBalance', minWidth: '100px' },
          { label: '上级分配金额', prop: 'higherAssignAmt', minWidth: '100px' },
          { label: '向下分配金额', prop: 'assignBelowAmt', minWidth: '100px' },
          { label: '上级扣回金额', prop: 'higherDeductAmt', minWidth: '100px' },
          { label: '从下级扣回金额', prop: 'deductBelowAmt', minWidth: '100px' },
          { label: '期末金额', prop: 'endingBalance', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            enabled: () => code('finance:reconci:cus:sub:detail'),
            id: '2',
            text: '明细',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/subAgencyBill/subAgencyBillDetail',
                query: {
                  orgId: row.orgId,
                  orgName: row.orgName,
                  billDate: row.billDate,
                  billDateType: this.searchParams.billDateType,
                },
              });
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          // userAccount: '',
          // userName: '',
          // phoneNumber: '',
          // userId: '',
          orgName: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const { orgName, orgId, billDate, billDateType } = this.$route.query;
      this.orgName = orgName;
      this.mainOrgId = orgId;
      this.billDate = billDate;
      this.billDateType = billDateType;
      Object.assign(this.searchParams, {
        orgId,
        billDate,
        billDateType,
        mainOrgId: this.mainOrgId,
      });
    },
    activated() {},
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            enabled: () => code('finance:reconci:cus:sub:export'),
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'finance_export',
                  jsonNode: {
                    downloadKey: 'childOrgFundBillExport',
                    ...this.searchParams,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      reconciliationApi() {
        return reconciliationApi;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '客户名称',
            placeholder: '请输入客户名称',
          },
        ];
      },
    },
    methods: {
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          commission.list({
            ...this.pageInfo,
            body: this.searchParams,
          }),
          commission.polymerization(this.searchParams),
        ]).then(([res1, res2]) => {
          this.tableData = res1.body;
          this.pageInfo.total = res1.totalCount || 0;
          this.polymerizationInfo = res2.body;
        });
      },
      /** 导出文件 */
      doExportFile() {
        commission.export('代理人佣金列表', this.searchParams);
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .big-icon.enterprise-icon {
        background-image: url('~@/assets/images/financial/accountManagement/enterprise@2x.png');
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
