<!-- 系统设置 权限管理 账号管理 改密 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close title="修改密码">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <template>
      <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
      <div class="drawer-main-body">
        <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
          <ykc-form-item label="账号" prop="account">
            {{ ruleForm.accountNo }}
          </ykc-form-item>
          <ykc-form-item label="姓名" prop="name">
            {{ ruleForm.accountName }}
          </ykc-form-item>
          <ykc-form-item label="密码重置" prop="accountPwd">
            <ykc-input
              minlength="6"
              maxlength="16"
              v-model="ruleForm.accountPwd"
              placeholder="请输入密码（6-16位的数字或字母）"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </template>
  </ykc-drawer>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import regexpObj from '@/utils/regexp';
  import { accountManage } from '@/service/apis';

  export default {
    props: {
      showCwd: {
        type: Boolean,
        default: false,
      },
      dataForm: {
        type: Object,
        default: () => {},
      },
    },
    watch: {
      showCwd(val) {
        this.showDrawer = val;
        if (val) {
          this.ruleForm = this.dataForm;
        }
      },
      showDrawer(val) {
        if (!val) {
          this.$emit('change-show-cwd', false);
        }
      },
    },
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.onAddDrawerCancel();
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.psConfirm();
            },
          },
        ],
        showDrawer: false,
        ruleForm: {
          accountPwd: '',
        },
        rules: {
          accountPwd: [
            {
              required: true,
              pattern: regexpObj.regexp.input.passwordType.regexp,
              message: regexpObj.regexp.input.passwordType.errorTips.error,
              trigger: 'blur',
            },
          ],
        },
      };
    },
    methods: {
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel() {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            this.showDrawer = false;
            done();
          },
        });
      },
      // 保存密码
      psConfirm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            console.log(this.ruleForm);
            const reqParams = {
              accountPwd: this.ruleForm.accountPwd,
              id: this.ruleForm.id,
            };
            accountManage.updatePass(reqParams).then(() => {
              this.showDrawer = false;
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
