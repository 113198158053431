<!--
 * @Author: wang peng
 * @Description: 道闸列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'asset:barrier:list:add'" type="plain" @click="add">
              新增
            </ykc-button>
            <ykc-button v-rbac="'asset:barrier:list:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          align="right"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <ykc-drawer
        :show.sync="showDrawer"
        :title="drawerTitle"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit ref="addOrEdit" :id="id"></AddOrEdit>
        </div>
      </ykc-drawer>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { barrierPage, treeUtils } from '@/service/apis';
  import { offlineExport, code } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'gateManagementList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: null,
        drawerType: 'add',
        showDrawer: false,
        stationIdData: [],
        searchParams: {
          name: '',
          stationName: '',
          cityId: '',
          protocol: '',
        },
        tableTitle: '道闸列表',
        tableData: [],
        addressData: [], // 区域数据组件使用数据源
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '道闸编码', prop: 'id', minWidth: '150px' },
          { label: '道闸名称', prop: 'name', minWidth: '150px' },
          { label: '归属电站', prop: 'stationName', minWidth: '150px' },
          { label: '城市', prop: 'city', minWidth: '150px' },
          { label: '接入模式', prop: 'protocolDesc', minWidth: '150px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '道闸名称',
            placeholder: '请输入道闸名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '归属电站',
            placeholder: '请选择归属电站',
            data: this.stationIdData,
          },
          {
            comName: 'YkcCascader',
            key: 'cityIds',
            label: '归属城市',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
          },
          {
            comName: 'YkcDropdown',
            key: 'protocol',
            label: '接入模式',
            placeholder: '请选择接入模式',
            data: [
              all,
              ...([
                { id: '1', name: 'server' },
                { id: '2', name: 'client' },
              ] ?? []),
            ],
          },
        ];
      },
      drawerTitle() {
        return `道闸${this.drawerType === 'edit' ? '编辑' : '新增'}`;
      },
    },
    created() {
      this.doFetchOrgInfos();
      this.requestGetDistrict();
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:barrier:list:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.ruleStatus === '3' || row.ruleStatus === '4'}
                onClick={() => {
                  this.id = row.id;
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:barrier:list:del'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.ruleStatus === '3' || row.ruleStatus === '4'}
                onClick={() => {
                  this.delBarrierFun(row);
                }}>
                删除
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:barrier:list:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/plantAssets/gateManagement/Detail',
                query: { id: row.id },
              });
            },
          },
        ];
      },
      /**
       * @desc 获取归属电站列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        barrierPage
          .queryForPage(reqParams)
          .then(res => {
            console.log(res);
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 新增
       * */
      add() {
        this.id = 0;
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /**
       * @desc 删除
       * */
      delBarrierFun(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '是否确认删除？删除之后无法恢复请谨慎操作',
          onConfirm: done => {
            console.log(done);
            barrierPage
              .delBarrier([row.id])
              .then(res => {
                console.log(res);
                this.$message.success('删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
          onCancel: done => {
            console.log(done);
            done();
          },
        });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            bizType: 'barrier',
            downloadType: 'barrier',
            jsonNode: {
              name: this.searchParams.name || '',
              stationName: this.searchParams.stationName || '',
              cityId: this.searchParams.cityId || '',
              protocol: this.searchParams.protocol || '',
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData+++111', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (this.searchParams.cityIds) {
          [, this.searchParams.cityId] = this.searchParams.cityIds;
        }
        delete this.searchParams.cityIds;

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        this.searchParams.cityIds = [];
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
