<!-------------------------------------------------
description: 告警列表 规则配置
/**
 *@author xiaosanbi 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <ykc-drawer
    title="规则配置"
    :show.sync="showDrawer"
    :before-ensure="submitForm"
    :before-cancel="beforeCancel1">
    <div class="drawer-main-body">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormDR">
        <ykc-form-item label="电桩故障停充" prop="type">
          <ykc-radio
            :data="[
              { id: 1, name: '停充' },
              { id: 2, name: '不停充' },
              { id: 3, name: '关停充电桩' },
            ]"
            v-model="ruleForm.type"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          prop="temp"
          label="高温停充"
          bottomTip="当温度达到高温停充温度时会触发告警，同时会停止当前充电">
          <ykc-input placeholder="请设置安全保护温度" v-model="ruleForm.temp"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          prop="soc"
          label="SOC异常"
          bottomTip="当连续上送SOC数据超出设置%时，会触发安全保护，停止充电">
          <ykc-input placeholder="SOC异常波动%" v-model="ruleForm.soc"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { omManagementApi } from '@/service/apis';

  export default {
    name: 'RuleConfig',
    components: {},
    props: {
      show2: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showDrawer: false,
        ruleForm: {
          soc: '',
          temp: '',
          type: 1,
        },
      };
    },
    watch: {
      show2(newValue, oldValue) {
        console.log(oldValue);
        this.showDrawer = newValue;
      },
    },
    computed: {
      linkText() {
        return this.ruleForm.operateObject === '1'
          ? '客户批量分配扣回.xlsx'
          : '用户批量分配扣回.xlsx';
      },
      modelUrl() {
        return this.ruleForm.operateObject === '1'
          ? `${process.env.VUE_APP_OSS}/finance/upload/%E5%AE%A2%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`
          : `${process.env.VUE_APP_OSS}/finance/upload/%E7%94%A8%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`;
      },
      rules() {
        return {
          // 电桩故障停充模式
          type: [{ required: true, message: '请选择电桩故障停充模式', trigger: 'blur' }],
          // 高温停充温度
          temp: [
            { required: true, message: '请设置安全保护温度', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              trigger: 'blur',
            },
          ],
          // SOC 异常波动比
          soc: [
            { required: true, message: '请设置 SOC 异常波动比', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.percentNumberType.regexp,
              message: regexpObj.regexp.input.percentNumberType.errorTips.error('异常波动比'),
              trigger: 'blur',
            },
          ],
        };
      },
    },
    created() {
      this.queryAlarmRule();
    },
    methods: {
      queryAlarmRule() {
        omManagementApi
          .queryAlarmRule({})
          .then(res => {
            const theDate = res[0];
            this.ruleForm = theDate || {
              soc: '',
              temp: '',
              type: 1,
            };
          })
          .catch(err => {
            console.error(err);
          });
      },
      beforeCancel1(done) {
        this.$emit('distribution-close', false);
        done();
        this.queryAlarmRule();
      },
      submitForm(done) {
        this.$refs?.ruleFormDR?.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              const formData = {
                ...this.ruleForm,
              };

              omManagementApi
                .saveAlarmRule(formData)

                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  this.$emit('distribution-close', false);
                  this.$emit('refresh-list', true);
                  done();
                })
                .catch(err => {
                  console.error(err);
                  if (err) {
                    this.$message.warning(err?.resultDesc);
                  }
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
    },
  };
</script>

<style scoped></style>
