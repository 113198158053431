<!--
/**
 *@author hhy 
 *@date 2023/2/7
  财务管理 发票管理 发票管理 
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="发票申请列表"
        operateFixedType="right"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-if="code('finance:invoice:apply')"
              class="apply-for-making-an-invoice"
              @click="doApplyForInvoice($event)"
              type="plain">
              申请开票
            </ykc-button>
            <ykc-button v-if="code('finance:invoice:export')" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <!-------------------------------------------------
    description:申请开票 抽屉
    --------------------------------------------------->
    <apply-for-billing
      :show.sync="showInvoiceDrawer"
      :customer-data="customerData"
      @drawer-close="onDrawerClose"
      @refresh-list="onAddDrawerRefresh" />
    <!-------------------------------------------------
    description:驳回原因 弹窗
    --------------------------------------------------->
    <reject-dialog
      :row="rejectRow"
      :show.sync="showRejectDialog"
      @refresh-list="onRejectDialogRefresh"
      @close-reject-dialog="onCloseRejectDialog" />
    <!-------------------------------------------------
    description:退回原因 弹窗
    --------------------------------------------------->
    <return-dialog
      :row="returnRow"
      :show.sync="showReturnDialog"
      @refresh-list="onReturnDialogRefresh"
      @close-return-dialog="onCloseReturnDialog" />
  </scroll-layout>
</template>

<script>
  import { code, offlineExport } from '@/utils';
  import { loadDicItem } from '@/utils/dictionary';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { invoiceManagements, reconciliationApi } from '@/service/apis';

  import RejectDialog from './components/RejectDialog.vue';
  import ReturnDialog from './components/ReturnDialog.vue';
  import ApplyForBilling from './components/ApplyForBilling.vue';

  export default {
    name: 'InvoiceManagementsList',
    components: {
      ReturnDialog,
      RejectDialog,
      ApplyForBilling,
    },
    data() {
      return {
        id: '',
        isEdit: false,
        showReturnDialog: false, // 显示返回弹窗
        showRejectDialog: false, // 驳回原因
        showInvoiceDrawer: false, // 申请开票弹窗
        ruleForm: {},
        rejectRow: {},
        returnRow: {},
        dictionary: {},
        searchParams: {},
        customerData: [],
        dictionaryStatus: [], // 发票状态字典
        dictionaryInvoiceType: [], // 发票类型字典
        tableData: [],
        tableColumns: [
          { label: '申请方', prop: 'applyUser', minWidth: '150px' },
          { label: '发票抬头', prop: 'invoiceTitle', minWidth: '150px' },
          { label: '发票种类', prop: 'invoiceTypeStr', minWidth: '150px' },
          { label: '发票形式', prop: 'invoiceSituationStr', minWidth: '100px' },
          { label: '税号', prop: 'taxNo', minWidth: '150px' },
          { label: '商户', prop: 'operatorName', minWidth: '150px' },
          { label: '电费（元）', prop: 'chargeFee', minWidth: '100px' },
          { label: '服务费（元）', prop: 'serviceFee', minWidth: '100px' },
          { label: '开票状态', prop: 'statusStr', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            enabled: () => code('finance:invoice:reject'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                {...{
                  props: {
                    disabled: !['1', '2'].includes(row.status),
                  },
                }}
                onClick={() => {
                  if (row.status === '1') {
                    this.showRejectDialog = true;
                    this.rejectRow = { ...row };
                  }
                  if (row.status === '2') {
                    this.showReturnDialog = true;
                    this.returnRow = { ...row };
                  }
                }}>
                {row.status === '1' || row.status === '3' ? '驳回' : '退回'}
              </ykc-button>
            ),
          },
          {
            id: '2',
            enabled: () => code('finance:invoice:make'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                {...{
                  props: {
                    disabled: !['1'].includes(row.status),
                  },
                }}
                onClick={() => {
                  if (row.status === '1') {
                    this.$router.push({
                      path: '/financialManagement/invoiceManagement/invoiceIssuingProcessing',
                      query: { id: row.id },
                    });
                  }
                }}>
                开票
              </ykc-button>
            ),
          },
          {
            id: '3',
            enabled: () => code('finance:invoice:detail'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                {...{
                  props: {
                    disabled: ['5'].includes(row.status),
                  },
                }}
                onClick={() => {
                  // 根据开票的状态来判断该跳转到哪里
                  switch (row.status) {
                    case '1':
                      this.$router.push({
                        path: '/financialManagement/invoiceManagement/invoiceDetailsToBeIssued',
                        query: { id: row.id },
                      });
                      break;
                    case '2':
                      this.$router.push({
                        path: '/financialManagement/invoiceManagement/invoiceDetailsIssued',
                        query: { id: row.id },
                      });
                      break;
                    case '3':
                      this.$router.push({
                        path: '/financialManagement/invoiceManagement/invoiceDetailsRejected',
                        query: { id: row.id },
                      });
                      break;
                    case '4':
                      this.$router.push({
                        path: '/financialManagement/invoiceManagement/invoiceDetailsReturned',
                        query: { id: row.id },
                      });
                      break;
                    default:
                      break;
                  }
                }}>
                详情
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDropdown',
            key: 'invoiceType',
            label: '发票种类',
            placeholder: '请选择',
            data: this.dictionaryInvoiceType,
          },
          {
            comName: 'YkcInput',
            key: 'invoiceTitle',
            label: '发票抬头',
            placeholder: '请输入发票抬头',
          },
          {
            comName: 'YkcInput',
            key: 'applyUser',
            label: '申请方',
            placeholder: '请输入申请方',
          },
          {
            comName: 'YkcInput',
            key: 'taxNo',
            label: '税号',
            placeholder: '请输入税号',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '商户',
            placeholder: '请输入商户',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '开票状态',
            placeholder: '请选择',
            data: this.dictionaryStatus,
          },
        ];
      },
    },
    created() {
      this.dictionaryInvoiceType = loadDicItem('invoice_type');
      this.dictionaryStatus = loadDicItem('invoice_status');
      this.searchTableList();
      this.queryAllMainOrgList();
    },
    methods: {
      code,
      queryAllMainOrgList() {
        reconciliationApi.queryAllMainOrgList({}).then(res => {
          // eslint-disable-next-line no-unused-vars
          this.customerData = res || [];
        });
      },
      onCloseRejectDialog() {
        this.showRejectDialog = false;
      },
      // 监听驳回刷新事件
      onRejectDialogRefresh() {
        this.showRejectDialog = false;
        this.searchTableList();
      },
      // 监听抽屉刷新事件
      onAddDrawerRefresh() {
        this.showInvoiceDrawer = false;
        this.searchTableList();
      },
      onCloseReturnDialog() {
        this.showReturnDialog = false;
      },
      // 监听退回刷新事件
      onReturnDialogRefresh() {
        this.showReturnDialog = false;
        this.searchTableList();
      },
      onDrawerClose() {
        this.showInvoiceDrawer = false;
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        done();
      },
      onCLose() {
        this.show1 = false;
      },
      /**
       * @description: 申请开票
       * */
      doApplyForInvoice() {
        this.showInvoiceDrawer = true;
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const invoiceManagementsReqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        invoiceManagements.list(invoiceManagementsReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        // invoiceManagements.export('发票申请列表', this.searchParams);
        offlineExport(
          {
            downloadType: 'finance_export',
            jsonNode: {
              downloadKey: 'invoiceApplyList',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
  .apply-for-making-an-invoice {
    margin-left: 8px;
  }
</style>
