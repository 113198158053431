<!--
 * @Author: wang peng
 * @Description:  新增/编辑
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="道闸名称" prop="name">
      <ykc-input placeholder="请输入道闸名称" maxlength="30" v-model="ruleForm.name"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="归属电站" prop="stationId">
      <ykc-dropdown
        :remote="true"
        :data="stationIdData"
        v-model="ruleForm.stationId"></ykc-dropdown>
    </ykc-form-item>

    <ykc-form-item label="协议模式" prop="protocol">
      <ykc-radio
        :data="[
          { id: 1, name: 'server' },
          { id: 2, name: 'client' },
        ]"
        v-model="ruleForm.protocol"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item label="场站编码" prop="gateCode" v-if="ruleForm.protocol === 2">
      <ykc-input
        placeholder="请输入场站编码"
        maxlength="30"
        v-model="ruleForm.gateCode"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="第三方账号" prop="thirdAccount" v-if="ruleForm.protocol === 2">
      <ykc-input
        placeholder="请输入第三方账号"
        maxlength="30"
        v-model="ruleForm.thirdAccount"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="应用编码" prop="applicationCode">
      <ykc-input
        placeholder="请输入应用编码"
        maxlength="30"
        v-model="ruleForm.applicationCode"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="秘钥" prop="secret">
      <ykc-input placeholder="请输入秘钥" maxlength="30" v-model="ruleForm.secret"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="停车减免类型" prop="discountType" v-if="ruleForm.protocol === 1">
      <ykc-radio
        :data="[
          { id: 1, name: '固定时长' },
          { id: 2, name: '充电时长' },
        ]"
        v-model="ruleForm.discountType"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item
      label="固定时长时间"
      prop="fixedTime"
      v-if="ruleForm.discountType === 1 && ruleForm.protocol === 1">
      <ykc-input
        type="number"
        placeholder="请输入固定时长时间"
        maxlength="30"
        v-model="ruleForm.fixedTime"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="订单推送地址" prop="orderAddress" v-if="ruleForm.protocol === 2">
      <ykc-input
        placeholder="请输入订单推送地址"
        maxlength="30"
        v-model="ruleForm.orderAddress"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="备注">
      <ykc-input
        maxlength="30"
        type="textarea"
        placeholder="请输入备注"
        v-model="ruleForm.remark"></ykc-input>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { barrierPage } from '@/service/apis';

  export default {
    props: {
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        stationIdData: [], // 归属电站数据
        ruleForm: {
          name: '',
          stationId: null,
          protocol: 2,
          secret: '',
          applicationCode: '',
          discountType: 1,
          fixedTime: null,
          gateCode: '',
          thirdAccount: '',
          orderAddress: '',
          remark: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入道闸名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('道闸名称'),
            },
          ],
          stationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          protocol: [{ required: true, message: '请选择协议模式', trigger: 'blur' }],
          secret: [
            { required: true, message: '请输入秘钥', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.secretType.regexp,
              message: regexpObj.regexp.input.secretType.errorTips.error('秘钥'),
            },
          ],
          applicationCode: [
            { required: true, message: '请输入应用编码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('应用编码'),
            },
          ],
          discountType: [{ required: true, message: '请选择停车减免类型', trigger: 'blur' }],
          fixedTime: [
            { required: true, message: '请输入固定时长时间', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('固定时长时间'),
            },
          ],
          gateCode: [
            { required: true, message: '请输入场站编码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('场站编码'),
            },
          ],
          thirdAccount: [
            { required: true, message: '请输入第三方账号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('第三方账号'),
            },
          ],
          orderAddress: [
            { required: true, message: '请输入订单推送地址', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('订单推送地址'),
            },
          ],
        },
      };
    },
    created() {
      this.doFetchOrgInfos();

      // 编辑回显
      if (this.id) {
        console.log('ok+++++');
        barrierPage
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          stationId: this.ruleForm.stationId,
          protocol: this.ruleForm.protocol,
          secret: this.ruleForm.secret,
          applicationCode: this.ruleForm.applicationCode,
          discountType: this.ruleForm.discountType,
          fixedTime: this.ruleForm.fixedTime,
          gateCode: this.ruleForm.gateCode,
          thirdAccount: this.ruleForm.thirdAccount,
          orderAddress: this.ruleForm.orderAddress,
          remark: this.ruleForm.remark,
        };

        if (this.id) {
          formData.id = this.id;
        }

        return formData;
      },
    },
    methods: {
      /**
       * @desc 获取归属列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              barrierPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              barrierPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /**
       *校验
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
