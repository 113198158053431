<!--
/**
 *@author hhy
 *@date 2023年2月10日
 财务管理 对账管理 客户账单
*/
-->
<template>
  <custom-list
    table-title="客户账单"
    search-btn-text="查询"
    reset-btn-text="恢复默认"
    warm-tips="温馨提示：请选择客户名称查看数据。"
    search-param-validate-err-msg="请选择客户名称进行查询"
    :show-header="true"
    :request-call="false"
    :search-data="searchData"
    :search-params="searchParam"
    :table-columns="tableColumns"
    :search-params-validate-keys="['orgId']"
    :request-method="reconciliationApi.queryMainFundBillForPage"
    :header-buttons="headerButtons.filter(item => item.enabled())"
    :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
    @query-form="handleSearchConfirm"
    @clear-form="handleSearchClean" />
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { directPage, reconciliationApi } from '@/service/apis';
  import { code, getLastTime, getLastYear, offlineExport } from '@/utils';

  export default {
    name: 'CustomerStatementsList',
    components: { CustomList },
    data() {
      return {
        id: '',
        isEdit: false,
        customerData: [], // 客户名称
        operatorListData: [], // 商户名称
        polymerizationInfo: {},
        tableData: [],
        tableColumns: [
          { label: '账单时间', prop: 'billDate', minWidth: '100px' },
          { label: '客户名称', prop: 'orgName', minWidth: '100px' },
          { label: '期初金额', prop: 'initialBalance', minWidth: '100px' },
          { label: '充值金额', prop: 'rechargeAmt', minWidth: '100px' },
          { label: '下级分配金额', prop: 'assignBelowAmt', minWidth: '100px' },
          { label: '从下级扣回金额', prop: 'deductBelowAmt', minWidth: '100px' },
          { label: '退款金额', prop: 'refundAmt', minWidth: '100px' },
          { label: '期末金额', prop: 'endingBalance', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            id: '1',
            text: '明细',
            enabled: () => code('finance:reconci:cus:cus:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/customerStatement/customerStatementsDetail',
                query: {
                  orgId: row.orgId,
                  orgName: row.orgName,
                  billDate: row.billDate,
                  billDateType: this.searchParam.billDateType,
                },
              });
            },
          },
          {
            id: '2',
            text: '子机构账单',
            enabled: () => code('URL:finance:reconci:cus:sub'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/subAgencyBill/subAgencyBill',
                query: {
                  orgName: row.orgName,
                  orgId: row.orgId,
                  billDate: row.billDate,
                  billDateType: this.searchParam.billDateType,
                },
              });
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParam: {
          date: [],
          billDateType: 1,
          billStartDate: '',
          billEndDate: '',
        },
      };
    },
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            enabled: () => code('finance:reconci:cus:cus:export'),
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'finance_export',
                  jsonNode: {
                    downloadKey: 'mainOrgFundBillExport',
                    ...this.searchParam,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      reconciliationApi() {
        return reconciliationApi;
      },
      searchData() {
        return [
          {
            key: 'date',
            isDateType: true,
            label: '账单日期',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            key: 'orgId',
            label: '客户名称',
            placeholder: '请输入客户名称',
            comName: 'YkcDropdown',
            data: this.customerData,
            remote: true,
          },
        ];
      },
    },
    created() {
      const startDate = getLastTime('yyyy-MM-dd', 7);
      const endDate = getLastTime('yyyy-MM-dd', 1);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.billStartDate = startDate;
      this.searchParam.billEndDate = endDate;
      this.queryAllMainOrgList();
    },
    methods: {
      queryOperatorList() {
        directPage.userOperatorList({ keyword: '' }).then(res => {
          this.operatorListData = (res || []).map(({ operatorId, name }) => ({
            name,
            id: String(operatorId),
          }));
        });
      },
      queryAllMainOrgList() {
        reconciliationApi.queryAllMainOrgList({}).then(res => {
          this.customerData = res || [];
        });
      },
      /**
       * 切换日月
       */
      handleChange(obj) {
        const { select, event } = obj;
        console.log('obj+++', obj);
        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(3, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.billStartDate = startDateStr;
          this.searchParam.billEndDate = endDateStr;
          this.searchParam.billDateType = 2;
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const startDateStr = getLastTime('yyyy-MM-dd', 7);
          const endDateStr = getLastTime('yyyy-MM-dd', 1);

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.billStartDate = startDateStr;
          this.searchParam.billEndDate = endDateStr;
          this.searchParam.billDateType = 1;
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData+++', searchData);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParam, searchData);
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParam.billStartDate, this.searchParam.billEndDate] = searchData.date;
        }
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);
        // 切换成日，默认状态
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 1);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.billStartDate = startDate;
        this.searchParam.billEndDate = endDate;
        this.searchParam.billDateType = 1;
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
