<template>
  <div class="page-container">
    <custom-list
      warm-tips=""
      :search-data="searchData"
      :search-params="searchParams"
      :show-header="true"
      table-title="司机列表"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons"
      :header-buttons="headerButtons.filter(item => item.enabled())"
      :request-method="cSideDriverAccount.queryPayRefundRecordForPage" />
  </div>
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';

  import { loadDicItem } from '@/utils/dictionary';
  import { getLastTime, offlineExport, code } from '@/utils';
  import { cSideDriverAccount, omManagementApi } from '@/service/apis';

  export default {
    name: 'ThirdPartyPaymentAccountsList',
    components: { CustomList },
    data() {
      return {
        omManagementApi,
        // 告警事项
        //  1=电桩故障 2=温度超高 3=soc异常 4=电桩离线 5=电压电流异常 6=实时数据异常
        dictionaryTypes: [
          { id: '1', name: '电桩故障' },
          { id: '2', name: '温度超高' },
          { id: '3', name: 'soc异常' },
          { id: '4', name: '电桩离线' },
          { id: '5', name: '电压电流异常' },
          { id: '6', name: '实时数据异常' },
        ],
        // 平台响应动作
        // 0=无 1=停止充电 2=关停充电桩
        dictionaryActions: [
          { id: '0', name: '无' },
          { id: '1', name: '停止充电' },
          { id: '2', name: '关停充电桩' },
        ],
        searchParams: {
          payTime: [getLastTime('yyyy-MM-dd', 30), getLastTime('yyyy-MM-dd', 0)],
          payStartTime: getLastTime('yyyy-MM-dd', 30),
          payEndTime: getLastTime('yyyy-MM-dd', 0),
        },
        tableColumns: [
          {
            label: '流水号',
            prop: 'flowNumber',
            scopedSlots: { defaultTitle: () => '' },
            minWidth: '120px',
          },
          {
            label: '流水类型',
            prop: 'flowType',
          },
          {
            label: '订单类型',
            prop: 'consumeType',
          },
          { label: '流水对象', prop: 'operatorName', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '流水时间',
            prop: 'flowTime',
            scopedSlots: { defaultTitle: () => '' },
            minWidth: '120px',
          },
          { label: '流水金额', prop: 'flowAmount', scopedSlots: { defaultTitle: () => '' } },
          { label: '用户ID', prop: 'userId', scopedSlots: { defaultTitle: () => '' } },
          { label: '用户账号', prop: 'userAccount', scopedSlots: { defaultTitle: () => '' } },
        ],
        tableOperateButtons: [],
        thirdPayFlowType: [],
        consumeOrderType: [],
      };
    },
    created() {
      this.thirdPayFlowType = loadDicItem('third_pay_flow_type');
      this.consumeOrderType = loadDicItem('consume_order_type');
    },
    methods: {
      handleChange(event) {
        const { date, select } = event;
        if (date && select === 'daterange') {
          this.searchParams.payStartTime = event?.date[0];
          this.searchParams.payEndTime = event?.date[1];
        }
      },
    },
    computed: {
      cSideDriverAccount() {
        return cSideDriverAccount;
      },
      headerButtons() {
        return [
          {
            label: '导出',
            enabled: () => code('finance:account:thirdparty:export'),
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'finance_export',
                  jsonNode: {
                    downloadKey: 'offlineExportThirdPayRefundRecord',
                    ...this.searchParams,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            isDateType: false,
            clearable: true,
            key: 'payTime',
            change: this.handleChange,
            label: '流水时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'userId',
            label: '用户ID',
            placeholder: '请输入用户ID',
          },
          {
            comName: 'YkcInput',
            key: 'flowNumber',
            label: '流水号',
            placeholder: '请输入流水号',
          },
          {
            comName: 'YkcDropdown',
            key: 'flowType',
            label: '流水类型',
            placeholder: '请选择流水类型',
            data: [...this.thirdPayFlowType],
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '流水对象',
            placeholder: '请输入流水对象',
          },
          {
            comName: 'YkcDropdown',
            key: 'orderType',
            label: '订单类型',
            placeholder: '请选择订单类型',
            data: [...this.consumeOrderType],
          },
        ];
      },
    },
  };
</script>

<style scoped></style>
