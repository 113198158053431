<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <scroll-layout class="scan-coupons-detail">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div>
      <ykc-detail :titles="['活动信息']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="80px" class="list-left">
              <ykc-detail-item label="活动名称：">
                <span>{{ dealData(detail.activityName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动类型：">
                <span>充值领取</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动状态：">
                <span>{{ statusLabel(detail.activityStatus) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动时间：">
                <span>
                  {{ dealData(detail.activityBeginTime) }} ~{{ dealData(detail.activityEndTime) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="修改时间：">
                <span>{{ dealData(detail.modifiedTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="修改人：">
                <span>{{ dealData(detail.modifiedAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建时间：">
                <span>{{ dealData(detail.createdTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动规则：">
                <div class="activity-photo">
                  <viewer :images="[detail.activityRule || defaultImageUrl]">
                    <img :src="detail.activityRule || defaultImageUrl" alt="" />
                  </viewer>
                </div>
              </ykc-detail-item>
              <ykc-detail-item label="活动说明：">
                <span>{{ dealData(detail.activityRemark) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail :titles="['活动配置']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="100px" class="list-left">
              <ykc-detail-item label="卡券设置：">
                <ykc-table
                  :data="detail.rewardList || []"
                  :columns="tableColumns"
                  :tableConfig="tableConfig"></ykc-table>
              </ykc-detail-item>
              <ykc-detail-item label="限领次数：">
                <span>
                  {{
                    dealData(
                      detail.limitCount,
                      detail.limitType === '2' ? '次/人/活动周期' : '次/人/天'
                    )
                  }}
                </span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
  </scroll-layout>
</template>

<script>
  import { couponActivity } from '@/service/apis';

  export default {
    props: {
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        defaultImageUrl: '',
        detail: {},
        treeData: [{ id: '1', label: '一级', children: [{ id: '2', label: '二级车队' }] }],
        carProperty: [],
        tableConfig: {
          stripe: false,
          border: false,
          // eslint-disable-next-line consistent-return
          spanMethod: ({ rowIndex, columnIndex }) => {
            if (columnIndex === 0) {
              // 用于设置要合并的列
              const size = this.mergeRows.length;
              if (size > 0) {
                for (let i = 0; i < size; i++) {
                  const item = this.mergeRows[i];
                  if (rowIndex === item.startRow && item.count >= 1) {
                    return {
                      rowspan: item.count, // 合并的行数
                      colspan: 1, // 合并的列数，设为０则直接不显示
                    };
                  }
                  if (rowIndex < item.startRow + item.count) {
                    return {
                      rowspan: 0,
                      colspan: 0,
                    };
                  }
                }
              }
            }
          },
        },
        mergeRows: [], // 需要合并的行
        tableColumns: [
          {
            label: '充值金额',
            prop: 'rewardThreshold',
            width: 240,
          },
          {
            label: '赠送卡券',
            prop: 'rewardName',
            width: 240,
          },
          { label: '发放数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        firstTableData: [],
        firstTableColumns: [
          { label: '电站编码', prop: 'stationId', minWidth: '150px' },
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          { label: '电站类型', prop: 'stationType', minWidth: '150px' },
          { label: '归属商户', prop: 'belongOperation', minWidth: '150px' },
          { label: '电站分组', prop: 'stationGroup', minWidth: '150px' },
        ],
        total: 10,
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {},
    created() {
      this.getDetail();
    },
    methods: {
      // eslint-disable-next-line consistent-return
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      handleSizeChange() {
        console.log('使用了sync修饰符', this.pageSize);
      },
      handleCurrentChange(val) {
        console.log('没有使用了sync修饰符', val);
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        couponActivity.rechargeActivityDetail({ activityId: this.activityId }).then(res => {
          if (res) {
            this.detail = res || {};
            this.setMergeRows();
          }
        });
      },
      setMergeRows() {
        if (this.detail?.rewardList?.length > 0) {
          let mergedStart = -1;
          let mergedCount = 0;
          let lasThreshold = -1;
          for (let i = 0; i < this.detail.rewardList.length; i++) {
            const reward = this.detail.rewardList[i];
            if (reward.rewardThreshold !== lasThreshold) {
              // 梯度和上个不一样，说明是一个新的梯度，梯度变化了
              if (mergedStart >= 0 && mergedCount > 0) {
                this.mergeRows.push({
                  startRow: mergedStart,
                  count: mergedCount,
                });
              }
              lasThreshold = reward.rewardThreshold;
              mergedStart = i;
              mergedCount = 1;
            } else {
              mergedCount++;
              if (i === this.detail.rewardList.length - 1) {
                // 最后一个也要设置一下
                if (mergedStart >= 0 && mergedCount > 0) {
                  this.mergeRows.push({
                    startRow: mergedStart,
                    count: mergedCount,
                  });
                }
              }
            }
          }
        }
      },
      dealData(data) {
        return data || '—';
      },
      /**
       * 获取字典
       */
      getDic(id) {
        const [property] = this.carProperty.filter(item => item.id === id);
        return property.name || '—';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .scan-coupons-detail {
    .all {
      margin-bottom: 16px;
    }
    .form-detail .ykc-detail-item-box {
      .ykc-table {
        padding: 0;
      }
    }
    .activity-photo {
      width: 80px;
      height: 80px;
      background: #f5f8ff;
      border-radius: 10px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
  }
</style>
