import _ from 'lodash';
import { Message } from 'element-ui';
// import dictionary from './dictionary';
import { sysDownload, treeUtils } from '@/service/apis';
import store from '@/store';

export function getToken() {
  const { token } = sessionStorage;
  if (!token) {
    // router.replace('/login');
    window.location = `${window.location.origin}/`;
    return null;
  }
  return token;
}

export const { cloneDeep } = _;

/**
 * 根据ID列表过滤数组的数据
 * @param {Array} list 需要过滤的数据
 * @param {Array} filterIds 需要过滤的id数组
 * @returns 过滤后的数据
 */
export const filterByIdList = (list, filterIds) => {
  return list?.filter(o => !filterIds.includes(o.id)) ?? [];
};

/**
 * 根据ID检索对应的数据的name
 * @param {Array} list 检索的数据列表
 * @param {*} id 需要检索的ID
 * @returns
 */
export const getListNameById = (list, id) => {
  const obj = list?.find(o => o.id === id);
  return obj?.name ?? '——';
};

/**
 * 保留两位小数点
 * @param
 * @returns
 */
export const toFixed = (value, num) => {
  const n = num || 2;
  return parseFloat(value || 0).toFixed(n);
};

/**
 * 根据ID检索对应的数据的id
 * @param {Array} list 检索的数据列表
 * @param {*} name 需要检索的Name
 * @returns
 */
export const getListIdByName = (list, name) => {
  const obj = list?.find(o => o.name === name);
  return obj?.id ?? '——';
};

export const dealData = (data, append) => {
  const label = append ? `${data + append}` : data;
  return label || '——';
};

/**
 * 处理详情页返回的字段数据
 * @param {*} data 数据源（整体数据源）必传
 * @param {*} key 字段key值 必传
 * @param {*} dicKey 字典植
 * @param {*} unit 单位 可不传
 */
// export const isDataDic = (data, key, dicKey, unit) => {
//   const unitStr = typeof unit === 'string' ? unit : '';
//   let retData = null;
//   if (data) {
//     if (data[key] || data[key] === 0) {
//       const syncDict = (
//         _.find(dictionary[dicKey], {
//           dictKey: _.toString(data[key]),
//         }) || {}
//       ).dictValue;
//       retData = syncDict ? syncDict + unitStr : '——';
//     }
//   }
//   return retData;
// };

/**
 * @class CtpStorage
 * @classdesc 业务存储缓存类封装
 * */
export class CtpStorage {
  /**
   * @desc 存储键值
   * @param key {String} 键
   * @param val { String | object } 值
   * @param {boolean | number} flag  [false] - 标志，1/true 为存到 sessionStorage 0/false 为存到 localStorage
   * */
  static setItem(key, val, flag = false) {
    if (flag) {
      if (typeof val === 'object') {
        sessionStorage[key] = JSON.stringify(val);
      } else {
        sessionStorage[key] = val;
      }
    }
    if (!flag) {
      if (typeof val === 'object') {
        localStorage[key] = JSON.stringify(val);
      } else {
        localStorage[key] = val;
      }
    }
  }

  /**
   * @desc 通过键删除值
   * @param key {String} 键
   * @param {boolean | number } flag  [false] - 标志，1/true 为存到 sessionStorage 0/false 为存到 localStorage
   * */
  static removeItem(key, flag = false) {
    if (flag) {
      sessionStorage.removeItem(key);
    }
    localStorage.removeItem(key);
  }
}

/**
 * 时间区间数据获取
 */
export function timeRangeInit(type) {
  // eslint-disable-next-line no-underscore-dangle
  const _timeBlock = [];
  for (let i = 0; i < 49; i++) {
    // eslint-disable-next-line no-underscore-dangle
    let __a = 1;
    // eslint-disable-next-line no-underscore-dangle
    let __b = 0;
    // eslint-disable-next-line no-underscore-dangle
    let __label = '';
    // eslint-disable-next-line no-underscore-dangle
    let __value = 0;
    // eslint-disable-next-line no-underscore-dangle
    let _temp = {};
    __a = Math.floor(i / 2);
    __a = __a > 9 ? __a.toString() : `0${__a}`;
    __b = (i % 2) * 30;
    __b = __b === 30 ? __b.toString() : `${__b}0`;

    __label = `${__a}:${__b}`;
    // TODO
    __value = i;

    if (type === 0) {
      _temp = {
        label: __label,
        value: __value + 1,
        disabled: i === 0,
      };
    } else {
      _temp = {
        label: __label,
        value: __value + 1,
        disabled: i === 48,
      };
    }

    _timeBlock[i.toString()] = _temp;
  }
  return _timeBlock;
}

// echarts专用求最大公约数 不含小数
export function chartlcm(a, b) {
  let result = 1;
  for (let i = 1; i <= a && i <= b; i++) {
    if (a % i === 0 && b % i === 0) {
      result = i;
    }
    if (result > 1 && i >= 5) break;
  }
  return result;
}

// 获取echarts  多Y轴的最大值和间隔值 lcmval 最大公约数   divisor 间隔数量
export function YmaxvalAndinterval(m, n, lcmval, divisor) {
  let interval1 = Math.ceil(m / lcmval);
  let interval2 = Math.ceil(n / lcmval);

  if (lcmval !== 1 && lcmval !== 0 && lcmval <= 10) {
    return { max1: m, max2: n, interval1, interval2 };
  }

  if (divisor === undefined || divisor === null) divisor = 5;

  m = Math.ceil(m / divisor) * divisor;
  n = Math.ceil(n / divisor) * divisor;

  interval1 = Math.ceil(m / divisor);
  interval2 = Math.ceil(n / divisor);

  return { max1: m, max2: n, interval1, interval2 };
}

/**
 * 取相邻整数
 */
export function getNextBiggestNumber(n) {
  const str = Math.trunc(n).toString();
  const len = str.length;
  const highestNum = parseInt(str[0], 10);
  const result = highestNum * 10 ** (len - 1);
  if (highestNum === 9) {
    return result;
  }
  return 10 ** (len - 1) * (highestNum + 1);
}

/**
 * @desc 获取n天之前时间
 * @returns {function(*, *): string}
 * days 可以不传，默认当天  0为当天， 1为昨天
 * exampleStr格式：'yyyy-MM-dd hh:mm:ss'
 * */
export function getLastTime(exampleStt, days) {
  const sst = exampleStt || 'yyyy-MM-dd';
  const day = days || 0; // 默认为当天
  const lastTime = new Date(Number(new Date().getTime()) + 480 * 60000 - day * 24 * 3600 * 1000)
    .toISOString()
    .replace(/T/, ' ')
    .replace(/\..+/, '');
  return sst ? ''.padStart(sst.length, lastTime) : '';
}

/**
 *@desc获取几个月前的输入日期
 * @date 输入日期(YYYY-MM)
 *@monthNum 月数
 */
export function getPreMonthDay(date, monthNum) {
  const dateArr = date.split('-');
  const year = dateArr[0]; // 获取当前日期的年份
  const month = dateArr[1]; // 获取当前日期的月份
  let year2 = year;
  let month2 = parseInt(month, 10) - monthNum;
  if (month2 <= 0) {
    const absM = Math.abs(month2);
    year2 = parseInt(year2, 10) - Math.ceil(absM / 12 === 0 ? 1 : parseInt(absM, 10) / 12);
    month2 = 12 - (absM % 12);
  }
  if (month2 < 10) {
    month2 = `0${month2}`;
  }
  const t2 = `${year2}-${month2}`;
  return t2;
}

/**
 * 获取最近1年时间区间，到月
 * @param {*} state
 * @param {*} getters
 * @endNum 结束时间月数 默认当前月
 */
export function getLastYear(num, endNum) {
  const endMonNum = endNum <= 0 ? endNum : 1;
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month =
    dateObj.getMonth() + endMonNum > 9
      ? dateObj.getMonth() + 1
      : `0${dateObj.getMonth() + endMonNum}`;
  const st = getPreMonthDay(`${year}-${month}`, num ? num - 1 : 11);
  const et = `${year}-${month}`;
  return { startTime: st, endTime: et };
}

/**
 * 唯一校验
 */
export function onlyCheck(opts) {
  const { _vue, url, body, value, callback } = opts;
  if (value && url) {
    // 否则请求校验
    _vue
      .$axios({
        url,
        data: {
          isLoad: false,
          body,
        },
      })
      .then(res => {
        if (res && res.body && res.body.businessCode && res.body.businessCode !== '0') {
          _vue.nameValidError = res.body.businessMsg;
          callback(new Error(_vue.nameValidError));
        } else {
          _vue.nameValidError = '';
          callback();
        }
      })
      .catch(() => {});
  }
}

/**
 * @description 这是一个检测 Storage 是否同时受支持和可用的函数：
 * @from https://developer.mozilla.org/zh-CN/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * */

export function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

/**
 * @desc 封装 session set 键 -> 值 操作
 * @param {string}  key 键
 * @param {string} val 值
 * */
export function sessionSetItem(key, val) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('sessionStorage')) {
    window.sessionStorage.setItem(key, val);
  } else {
    throw new Error('无法调用 sessionStorage API');
  }
}

/**
 * @desc 封装 session get key 操作
 * @param key {string} 键
 * @return {string} data
 * */
export function sessionGetItem(key) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('sessionStorage')) {
    const val = window.sessionStorage.getItem(key);
    // eslint-disable-next-line no-use-before-define
    if (isJSONString(val)) {
      return JSON.parse(val);
    }
    return val;
  }
  throw new Error('无法调用 sessionStorage API');
}

/**
 * @desc 封装 session removeItem  key 操作
 * @param key {string} 键
 * */
export function sessionRemoveItem(key) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('sessionStorage')) {
    window.sessionStorage.removeItem(key);
  } else {
    throw new Error('无法调用 sessionStorage API');
  }
}

/**
 * @desc 封装 session clear 操作
 * @return void 0
 * */
export function sessionClear() {
  if (storageAvailable('sessionStorage')) {
    window.sessionStorage.clear();
  } else {
    throw new Error('无法调用 sessionStorage API');
  }
}

/**
 * @desc 封装 localStorage setItem 操作
 * @param key {string} 键
 * @param val {string} 值
 * */
export function localSetItem(key, val) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(key, val);
  } else {
    throw new Error('无法调用 localStorage API');
  }
}

/**
 * @desc 封装 localStorage getItem 操作
 * @param key {string} 键
 * @return {string} data
 * */
export function localGetItem(key) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('localStorage')) {
    const val = window.localStorage.getItem(key);
    // eslint-disable-next-line no-use-before-define
    if (isJSONString(val)) {
      return JSON.parse(val);
    }
    return val;
  }
  throw new Error('无法调用 localStorage API');
}

/**
 * @desc 封装 localStorage removeItem 操作
 * @param key {string} 键
 * */
export function localRemoveItem(key) {
  if (!key) {
    throw new Error('键不能为空！');
  }
  if (storageAvailable('localStorage')) {
    return window.localStorage.removeItem(key);
  }
  throw new Error('无法调用 localStorage API');
}

/**
 * @desc 封装 localStorage clear 操作
 * @return void 0
 * */
export function localClear() {
  if (storageAvailable('sessionStorage')) {
    window.localStorage.clear();
  } else {
    throw new Error('无法调用 localStorage API');
  }
}

/**
 * @desc 是否是一个 JSON String
 * @param str {string} 字符串
 * @return {boolean}
 * */
export function isJSONString(str) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

/**
 * @desc 全局离线下载
 * @param obj 对象，包含{downloadType,jsonNode}
 * @param {function} [cb]  - 自行处理的逻辑（内部业务逻辑）
 * @param number {string | number}  条数
 * @param obj.downloadType {string} 字符串 下载类型（参考字典download_type，每个导出对应一个类型）
 * @param obj.jsonNode {Object}  对象 每个业务查询参数 ，对象方式传递，key-value
 * @example
 * obj.jsonNode {Object} :
 * {"opAccount":"chenxd"}
 * @return void
 * */
export function offlineExport(obj, number, cb) {
  try {
    const limit = 50000;
    if ((Number(number) || 0) > limit) {
      Message.error('当前导出数量超过平台上限，导出失败');
      throw new Error('Reached limit of 50000');
    }

    sysDownload
      .offlineExport(obj)
      .then(res => {
        cb && cb(res);
        store.commit('setDownloadDialog', true);
      })
      .catch(err => {
        console.error(err);
        throw new Error('Operation failed');
      });
  } catch (err) {
    console.error(err);
  }
}

export function getDistrict(districtId) {
  let city = '';
  treeUtils.getDistrict({}).then(res => {
    const allData = res.districts;
    for (let i = 0; i < allData.length; i++) {
      // eslint-disable-next-line no-loop-func
      allData[i].subNodes.forEach(element => {
        if (element.id === districtId) {
          city = `${allData[i].name} ${element.name}`;
          console.log(city);
        }
      });
    }
  });
  return city;
}

function deepSetValue(list, map) {
  list.forEach(item => {
    if (item.subNodes) {
      deepSetValue(item.subNodes, map);
    }
    item.permissionCode && map.set(`URL:${item.permissionCode}`, true);
  });
}

const urlPermissionMap = new Map();
function urlCode(permissionCode) {
  if (!urlPermissionMap.size) {
    urlPermissionMap.clear();
    const menuData = sessionGetItem('menuData') || [];
    deepSetValue(menuData, urlPermissionMap);
  }
  return !!urlPermissionMap.get(permissionCode);
}

/**
 * code有两种 一种是按钮的code，一种是url的code，通过URL前缀区分一下
 */
export function code(permissionCode) {
  if (permissionCode.startsWith('URL:')) {
    return urlCode(permissionCode);
  }
  let permission = false;
  const codePermission = sessionGetItem('codePermission') || [];
  const itemResult = codePermission.filter(item => item.permissionCode === permissionCode);
  if (itemResult.length > 0) {
    permission = true;
  }

  return permission;
}
