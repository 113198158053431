<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item class="file-error" label="选择文件" prop="file">
      <ykc-upload
        type="xlsx"
        :size="5"
        ref="file"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleRemove="handleRemove"
        @handleChange="fileUpload"
        :temText="linkText"
        :temhref="modelUrl"></ykc-upload>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { filterByIdList } from '@/utils/index';
  import { customerOrg } from '@/service/apis';

  export default {
    data() {
      return {
        dictionary: {},
        rules: {
          discountType: [{ required: true, message: '请选择折扣类型', trigger: 'blur' }],
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
        ruleForm: {
          discountType: '1',
          file: undefined,
        },
        formData: null,
      };
    },
    computed: {
      modelUrl() {
        return `${process.env.VUE_APP_OSS}/resource/车辆导入.xlsx`;
      },
      linkText() {
        return '车辆导入模板.xlsx';
      },
    },
    created() {
      // 获取数据字典的数据
      this.$store.dispatch('getDictionaryByKeys', ['DISCOUNT_TYPE']).then(res => {
        this.dictionary = res;
      });
    },
    methods: {
      filterByIdList,
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          this.formData = new FormData();
          const param = {
            file: file.raw,
          };
          Object.entries(param).forEach(([k, v]) => {
            this.formData.append(k, v);
          });
        }
        this.$refs.ruleForm.validateField('file');
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            customerOrg.importCompanyCar(this.formData).then(() => {
              this.$message({
                message: '批量导入成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
