<!--
 * @Description: 滚动布局
 * @Author: 汪佳彪
 * @date: 2021-11-06 13:57
-->
<template>
  <div class="scroll-layout">
    <div class="scroll-layout-header" ref="Header">
      <slot name="header"></slot>
    </div>
    <el-scrollbar
      ref="scrollbar"
      :class="{
        'scroll-layout-content': true,
        'footer-absolute': footerType === 'fixed',
      }">
      <slot></slot>
    </el-scrollbar>
    <RecordNo v-if="showFooter" style="position: absolute; bottom: 0; width: 100%" />
  </div>
</template>

<script>
  import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';

  export default {
    props: {
      showFooter: {
        type: Boolean,
        default: true,
      },
    },
    name: 'ScrollLayout',
    components: {
      // 备案号
      RecordNo: {
        // eslint-disable-next-line no-unused-vars
        render(h) {
          return (
            <div class="footer">
              <div style="white-space: nowrap; overflow:hidden;">
                备案号：
                <a target="_blank" href="http://beian.miit.gov.cn/">
                  皖ICP备2023008312号
                </a>
                Copyright©2022Co..Ltd.All Rights Reserved
                <span class="test">版权所有：滁州工投新能源开发有限公司</span>
              </div>
            </div>
          );
        },
      },
    },
    data() {
      return {
        footerType: 'scroll',
      };
    },
    activated() {
      this.$nextTick(this.update);
    },
    mounted() {
      const vm = this.$refs.scrollbar;
      if (vm?.$refs?.resize) {
        // 滚动区域resize
        addResizeListener(vm.$refs.resize, this.update);
        // 头部区域resize
        addResizeListener(this.$refs.Header, this.update);
        // window resize
        window.addEventListener('resize', this.update);
        this.$once('hook:beforeDestroy', () => {
          removeResizeListener(vm.$refs.resize, this.update);
          removeResizeListener(this.$refs.Header, this.update);
          window.removeEventListener('resize', this.update);
        });
      }

      this.$nextTick(() => {
        if (this.$refs.YkcTable) {
          let timer = setTimeout(() => {
            this.$refs.YkcTable.$refs.Table.layout.viewportHeight =
              this.$refs.YkcTable.$refs.Table.layout.tableHeight;
          }, 10);

          this.$on('hook:activated', () => {
            if (timer === null) {
              timer = setTimeout(() => {
                this.$refs.YkcTable.$refs.Table.layout.viewportHeight =
                  this.$refs.YkcTable.$refs.Table.layout.tableHeight;
              }, 10);
            }
          });

          this.$on('hook:deactivated', () => {
            clearTimeout(timer);
            timer = null;
          });
        }
      });
    },
    methods: {
      update() {
        const vm = this.$refs.scrollbar;
        if (vm && typeof vm.update === 'function') {
          // 滚动条更新
          vm.update();
          this.$emit('scrollbar-update');
        }
        // 更新底部备案号
        if (vm && vm.wrap) {
          const { scrollHeight, clientHeight } = vm.wrap;
          // console.log(clientHeight, scrollHeight);
          if (scrollHeight === clientHeight) {
            this.footerType = 'fixed';
          } else {
            this.footerType = 'scroll';
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .scroll-layout {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-header {
      margin: 10px 20px 10px 20px;
      > *:not(:first-child) {
        margin-top: 12px;
      }
      .ykc-button + .ykc-button {
        margin-left: 8px;
      }
    }
    &-content {
      &.footer-absolute {
        .el-scrollbar__bar.is-horizontal {
          bottom: 22px;
        }
      }
      flex: 1;
      margin: 0 20px 30px 20px;
      .el-scrollbar__wrap {
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
        .el-scrollbar__view {
          height: 100%;
          > div {
            // min-width: 1246px;
          }
        }
      }
      .el-scrollbar__bar {
        &.is-vertical {
          opacity: 1;
        }
        &.is-horizontal {
          z-index: 20;
        }
      }
    }
    .footer {
      background-color: $_EDEDED;
      color: $_9B9B9B;
      font-size: 12px;
      text-align: center;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        font-size: 16px;
        transform: scale(0.625);
        a {
          color: $_9B9B9B;
          margin-right: 4px;
          text-decoration: none;
        }
      }
    }
  }
</style>
