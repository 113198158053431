<!--
/**
 *@author hhy 
 *@date 2023-2-9
  财务管理 对账管理 互联互通账单明细 
*/
-->
<template>
  <custom-list
    warm-tips=""
    table-title="互联互通账单明细"
    :show-header="true"
    :show-top-buttons="true"
    :search-data="searchData"
    :search-params="searchParams"
    :table-columns="tableColumns"
    :header-buttons="headerButtons"
    :page-top-buttons="pageTopButtons"
    :table-operate-buttons="tableOperateButtons"
    :request-method="reconciliationApi.linkBillDetailPageList"
    @clear-form="clearForm" />
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import CustomList from '@/components/biz/CustomList.vue';

  import { offlineExport } from '@/utils';
  import { commission, reconciliationApi } from '@/service/apis';

  export default {
    name: 'InterconnectedBillingStatementsDetail',
    components: { CustomList },
    data() {
      return {
        reconciliationApi,
        isEdit: false,
        id: '',
        tradeTime: '',
        queryType: '',
        searchParams: {},
        pageButtons: [
          {
            id: '2',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableData: [],
        tableOperateButtons: [],
        tableColumns: [
          { label: '订单号', prop: 'orderNo', minWidth: '200px' },
          { label: '互联单号', prop: 'tradeSeq', minWidth: '240px' },
          { label: '电站名称', prop: 'stationName', minWidth: '200px' },
          { label: '运营商名称', prop: 'operatorName', minWidth: '200px' },
          { label: '结算时间', prop: 'tradeTime', minWidth: '200px' },
          { label: '用户账号', prop: 'userName', minWidth: '200px' },
          { label: '充电量', prop: 'chargeAmount', minWidth: '150px' },
          { label: '标准价电费', prop: 'standardFee', minWidth: '150px' },
          {
            label: '标准价服务费',
            prop: 'standardServiceFee',
            minWidth: '150px',
          },
          {
            label: '平台电费活动抵扣',
            prop: 'platformFeeDiscount',
            minWidth: '150px',
          },
          {
            label: '平台服务费活动抵扣',
            prop: 'platformServiceFeeDiscount',
            minWidth: '150px',
          },
          {
            label: '平台电费卡券抵扣',
            prop: 'platformChargeCouponDiscountAmt',
            minWidth: '150px',
          },
          {
            label: '平台服务费卡券折扣',
            prop: 'platformServiceCouponDiscountAmt',
            minWidth: '150px',
          },
          {
            label: '商户电费活动抵扣',
            prop: 'operatorFeeDiscount',
            minWidth: '150px',
          },
          {
            label: '商户服务费活动抵扣',
            prop: 'operatorServiceFeeDiscount',
            minWidth: '150px',
          },
          {
            label: '商户电费卡券抵扣',
            prop: 'operatorChargeCouponDiscountAmt',
            minWidth: '150px',
          },
          {
            label: '商户服务费卡券抵扣',
            prop: 'operatorServiceCouponDiscountAmt',
            minWidth: '150px',
          },
          {
            label: '实付电费',
            prop: 'actuallyPaidFee',
            minWidth: '150px',
          },
          {
            label: '实付服务费',
            prop: 'actuallyPaidServiceFee',
            minWidth: '150px',
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
      };
    },
    created() {
      const { id, tradeTime, queryType } = this.$route.query;
      this.id = id;
      this.tradeTime = tradeTime;
      this.queryType = queryType;
      this.searchParams.userId = id;
      this.searchParams.userCompanyId = id;
      this.searchParams.tradeTime = tradeTime;
      this.searchParams.queryType = queryType;
    },
    activated() {},
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'LINK_BILL_DETAIL',
                  jsonNode: {
                    // downloadKey: 'message',
                    ...this.searchParams,
                  },
                },
                this.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'orderNo',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '运营商名称',
            placeholder: '请输入运营商名称',
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '互联单号',
            placeholder: '请输入互联单号',
          },
        ];
      },
    },
    methods: {
      clearForm(data) {
        this.searchParams = {
          ...data,
          userId: this.id,
          userCompanyId: this.id,
          tradeTime: this.tradeTime,
          queryType: this.queryType,
        };
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {},
      /** 导出文件 */
      doExportFile() {
        commission.export('代理人佣金列表', this.searchParams);
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
