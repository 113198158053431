<!--
 * @Author: wang peng
 * @Description: 直联电站新增
-->
<template>
  <ykc-form class="el-form-wrap directConnectionStation-detail">
    <!--温馨提示-->
    <ykc-warm-tip
      type="info"
      show-icon
      multi-line
      description="温馨提示：当前步骤配置基于通用监管补贴要求，请如实填写，以便影响补贴申报。"></ykc-warm-tip>
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="基础信息" />
      <el-step title="监管信息" />
      <el-step title="运营信息" />
    </el-steps>
    <div>
      <!-- 基础信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-show="active === 0">
        <ykc-form-item label="电站名称" prop="name">
          <ykc-input
            placeholder="请输入电站名称"
            maxlength="50"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>

        <ykc-form-item
          class="img-error"
          label="归属商户"
          prop="operatorId"
          bottomTip="温馨提示：归属商户变更后，统计、财务等信息均发生变化，请谨慎操作">
          <ykc-dropdown
            :remote="true"
            :data="OperatorListData"
            v-model="ruleForm.operatorId"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="行政区域编码" prop="areaCodeCountrySide">
          <ykc-input
            placeholder="请输入行政区域编码"
            maxlength="12"
            v-model="ruleForm.areaCodeCountrySide"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电站地址" prop="addressId">
          <ykc-cascader
            :isObject="true"
            :options="addressData"
            v-model="ruleForm.addressId"
            @change="changeDistrict"></ykc-cascader>
        </ykc-form-item>

        <ykc-form-item label="详细地址" prop="detailAddress">
          <div class="flex">
            <ykc-input
              placeholder="请输入详细地址"
              maxlength="100"
              v-model="ruleForm.detailAddress"
              style="flex: 1; margin-right: 10px"></ykc-input>
            <ykc-button @click="clickAddress">地图定位</ykc-button>
          </div>
        </ykc-form-item>

        <ykc-form-item>
          <div class="flex">
            <station-map
              ref="ykcMap"
              class="ykc-map"
              style="flex: 1"
              @mapClick="mapClick"></station-map>
          </div>
        </ykc-form-item>
      </ykc-form>
      <!-- 监管信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-show="active === 1">
        <ykc-form-item label="电站位置" prop="locationAddress">
          <ykc-radio
            :data="[
              { id: 1, name: '地上电站' },
              { id: 2, name: '地下电站' },
            ]"
            v-model="ruleForm.locationAddress"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="可用总电容" prop="capacity">
          <ykc-input maxlength="8" placeholder="请输入可用总电容" v-model="ruleForm.capacity">
            <template slot="append">KV·A</template>
          </ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电站类型" prop="stationType">
          <ykc-dropdown :data="stationTypeData" v-model="ruleForm.stationType"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="建设场所" prop="buildAddress">
          <ykc-dropdown
            :data="this.buildAddressData"
            v-model="ruleForm.buildAddress"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="值守人员" prop="dutyCount">
          <ykc-input
            maxlength="8"
            placeholder="请输入值守人员"
            v-model="ruleForm.dutyCount"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="是否独立报装" prop="isAloneApply">
          <ykc-radio
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            v-model="ruleForm.isAloneApply"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="是否公共停车场" prop="isPublicParkingLot">
          <ykc-radio
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            v-model="ruleForm.isPublicParkingLot"></ykc-radio>
        </ykc-form-item>
      </ykc-form>
      <!-- 运营信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm2"
        class="el-form-wrap"
        v-show="active === 2">
        <ykc-form-item label="场站辅助设备">
          <ykc-checkbox
            :data="dataBtn"
            :configSet="{
              label: 'label',
              value: 'value',
            }"
            v-model="ruleForm.selectCheckbox3"
            @change="checkboxChange"></ykc-checkbox>
        </ykc-form-item>

        <ykc-form-item label="停车收费模式" prop="parkFeeType">
          <ykc-dropdown :data="parkFeeTypeData" v-model="ruleForm.parkFeeType"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="停车收费提示" prop="parkFeeTip">
          <ykc-input
            placeholder="请输入停车收费提示"
            maxlength="30"
            v-model="ruleForm.parkFeeTip"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="站点标签">
          <ykc-dropdown
            :multiple="true"
            :multipleLimit="4"
            :data="this.stationLabelListData"
            v-model="ruleForm.stationLabelList"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="营业时间">
          <electrovalence-time-range
            ref="range"
            style="padding-top: 10px"
            v-model="ruleForm.rangeValue"
            :maxCount="1"
            :clearable="true"
            :selectedStatus.sync="selectedStatus"
            :groups="[{ value: 'active', label: 'active', background: 'pink' }]">
            <template #table="{ data }">
              <span style="font-size: 10px">
                选定当前营业时间区间: {{ data[0]?.start || '--' }}~{{ data[0]?.end || '--' }}
              </span>
            </template>
          </electrovalence-time-range>
        </ykc-form-item>

        <ykc-form-item
          prop="img"
          label="电站图片"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过1M">
          <div class="flex upload-img-box">
            <ykc-upload
              v-for="(item, index) in uploadData"
              :key="index"
              :text="item.name"
              :ref="`stationFile${item.id}`"
              @handleChange="uploadImg"
              @handleRemove="handleRemoveLogo"></ykc-upload>
          </div>
        </ykc-form-item>
        <ykc-form-item label="备注">
          <ykc-input
            maxlength="500"
            type="textarea"
            placeholder="请输入备注"
            v-model="ruleForm.remark"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { directPage, stationPage, treeUtils, uploadUtils } from '@/service/apis';

  import StationMap from './StationMap';
  import ElectrovalenceTimeRange from '@/components/electrovalence-time-range.vue';

  export default {
    name: 'AddOrEdit',
    components: {
      StationMap,
      ElectrovalenceTimeRange,
    },
    props: {
      id: {
        type: Number,
        default: 0,
      },
      active: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        activeId: '',
        addressName: '', // 省市区名称
        selectedStatus: 'active',
        detail: {}, // 详情数据
        addressData: [], // 城市数据源
        OperatorListData: [], // 归属商户数据源
        dataBtn: [
          {
            value: 1,
            label: '道闸',
          },
          {
            value: 2,
            label: '地锁',
          },
        ],
        // 电站类型原数据
        stationTypeData: [
          { id: 1, name: '公共' },
          { id: 50, name: '个人' },
          { id: 100, name: '公共(专用)' },
          { id: 101, name: '环卫(专用)' },
          { id: 102, name: '物流(专用)' },
          { id: 103, name: '出租车(专用)' },
          { id: 255, name: '其他' },
        ],
        // 建设场站原数据
        buildAddressData: [
          { id: '1', name: '居民区' },
          { id: '2', name: '公共机构' },
          { id: '3', name: '企事业单位' },
          { id: '4', name: '写字楼' },
          { id: '5', name: '工业园区' },
          { id: '6', name: '交通枢纽' },
          { id: '7', name: '大型文体设施' },
          { id: '8', name: '城市绿地' },
          { id: '9', name: '大型建筑配建停车场' },
          { id: '10', name: '路边停车位' },
          { id: '11', name: '城际高速服务区' },
          { id: '255', name: '其他' },
        ],
        // 停车收费模式原数据
        parkFeeTypeData: [
          { id: 1, name: '免费停车' },
          { id: 2, name: '收费停车' },
          { id: 3, name: '充电限免' },
        ],
        // 站点标签原数据
        stationLabelListData: [
          { id: '1', name: '免费WIFI' },
          { id: '2', name: '空调休息室' },
          { id: '3', name: '按摩室' },
          { id: '4', name: '便利店' },
          { id: '5', name: '洗车' },
          { id: '6', name: '饮用水' },
          { id: '7', name: '厕所' },
          { id: '8', name: '快餐' },
          { id: '9', name: '自动售货机' },
          { id: '10', name: '雨棚' },
        ],
        uploadData: [
          { id: 1, name: '主入口图' },
          { id: 2, name: '标志路径' },
          { id: 3, name: '电站全景' },
          { id: 4, name: '电桩特写' },
          { id: 5, name: '其他图片' },
        ],
        ruleForm: {
          name: '', // 电站名称
          areaCodeCountrySide: '', // 行政区域编码
          operatorId: null, // 归属商户
          addressId: [], // 省市区
          provinceId: '',
          cityId: '',
          districtId: '',
          detailAddress: '',
          longitude: null,
          latitude: null,
          locationAddress: 1,
          capacity: null,
          stationType: null,
          buildAddress: '',
          dutyCount: 0,
          isAloneApply: 0,
          isPublicParkingLot: 0,
          parkingLockFlag: 0,
          isBarrierGate: 0,
          parkFeeType: '',
          parkFeeTip: '',
          rangeValue: [], // 时间选择区间
          selectCheckbox3: [],
          stationLabelList: [],
          stationImageList: [],
          stationOperateTimeList: [],
          remark: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入电站名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电站名称'),
            },
          ],
          operatorId: [{ required: true, message: '请选择归属商户', trigger: 'blur' }],
          areaCodeCountrySide: [
            { required: true, message: '请输入行政区域编码', trigger: 'blur' },
            { min: 12, max: 12, message: '长度为12位纯数字', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.decimalNumberType_12.regexp,
              message: regexpObj.regexp.input.decimalNumberType_12.errorTips.error(
                '行政区域编码',
                '12位纯数字'
              ),
            },
          ],
          addressId: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
          detailAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('详细地址'),
            },
          ],
          locationAddress: [{ required: true, message: '请选择电站位置', trigger: 'blur' }],
          capacity: [
            { required: true, message: '请输入可用总电容', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '可用总电容',
                99999999
              ),
            },
          ],
          stationType: [{ required: true, message: '请选择电站类型', trigger: 'blur' }],
          buildAddress: [{ required: true, message: '请选择建设场所', trigger: 'blur' }],
          dutyCount: [
            { required: true, message: '请输入值守人员', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '值守人员',
                99999999
              ),
            },
          ],
          isAloneApply: [{ required: true, message: '请选择是否独立报装', trigger: 'blur' }],
          isPublicParkingLot: [
            { required: true, message: '请选择是否公共停车场', trigger: 'blur' },
          ],
          parkFeeType: [{ required: true, message: '请选择停车收费模式', trigger: 'blur' }],
          parkFeeTip: [
            { required: true, message: '请输入停车收费提示', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('停车收费提示'),
            },
          ],
        },
      };
    },
    created() {
      // 获取机构下拉列表/获取树,新增获取
      this.requestGetDistrict();
      this.requestOperatorList();

      // 编辑回显
      if (this.id) {
        stationPage
          .dirDetail({ stationId: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };

            // 电站地址
            this.ruleForm.addressId = [
              res.area[0].id || null,
              res.area[1].id || null,
              res.area[2].id || null,
            ];

            this.addressName = res.area[0].name + res.area[1].name + res.area[2].name;

            // 设置定位
            if (this.$refs.ykcMap) {
              this.$refs.ykcMap.setLngLat(this.ruleForm.longitude, this.ruleForm.latitude);
            }

            // 道闸选取
            if (this.ruleForm.isBarrierGate === 1) {
              this.ruleForm.selectCheckbox3.push(1);
            }

            if (this.ruleForm.parkingLockFlag === 1) {
              this.ruleForm.selectCheckbox3.push(2);
            }

            //  标签数据格式转换
            if (this.ruleForm.stationLabelList?.length > 0) {
              this.ruleForm.stationLabelList = res.stationLabelList.map(String);
            }

            // 电站时间选取
            this.ruleForm.rangeValue = [
              {
                type: 'active',
                start: res.operateTimeVOList[0].startPeriod,
                end: res.operateTimeVOList[0].endPeriod,
              },
            ];
            this.selectedStatus = 'active';

            // 电站图片回显
            if (res.picsVOList && res.picsVOList.length !== 0) {
              res.picsVOList.forEach(item => {
                this.$nextTick(() => {
                  this.$refs[`stationFile${item.locationIndex}`][0].fileList = [{ url: item.url }];
                  this.ruleForm.stationImageList.push({
                    url: item.url,
                    locationIndex: item.locationIndex,
                  });
                });
              });
            }
            this.$nextTick(() => {
              this.clickAddress();
            });
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          areaCodeCountrySide: this.ruleForm.areaCodeCountrySide,
          operatorId: this.ruleForm.operatorId,
          provinceId: this.ruleForm.provinceId,
          cityId: this.ruleForm.cityId,
          districtId: this.ruleForm.districtId,
          detailAddress: this.ruleForm.detailAddress,
          longitude: this.ruleForm.longitude,
          latitude: this.ruleForm.latitude,
          locationAddress: this.ruleForm.locationAddress,
          capacity: this.ruleForm.capacity,
          stationType: this.ruleForm.stationType,
          buildAddress: this.ruleForm.buildAddress,
          dutyCount: this.ruleForm.dutyCount,
          isAloneApply: this.ruleForm.isAloneApply,
          isPublicParkingLot: this.ruleForm.isPublicParkingLot,
          parkingLockFlag: this.ruleForm.parkingLockFlag,
          isBarrierGate: this.ruleForm.isBarrierGate,
          parkFeeType: this.ruleForm.parkFeeType,
          parkFeeTip: this.ruleForm.parkFeeTip,
          stationLabelList: this.ruleForm.stationLabelList,
          stationOperateTimeList:
            this.ruleForm.rangeValue.length !== 0
              ? [
                  {
                    startPeriod: this.ruleForm.rangeValue[0]?.start,
                    endPeriod: this.ruleForm.rangeValue[0]?.end,
                  },
                ]
              : [], // 营业时间
          stationImageList: this.ruleForm.stationImageList, // 电站图片
          remark: this.ruleForm.remark,
        };

        if (this.id) {
          formData.stationId = this.id;
        }

        return formData;
      },
    },
    methods: {
      /**
       * 场站辅助设备选取change方法调用信息提示
       */
      checkboxChange(e) {
        if (e.includes(1)) {
          this.ruleForm.isBarrierGate = 1;
        } else {
          this.ruleForm.isBarrierGate = 0;
        }

        if (e.includes(2)) {
          this.ruleForm.parkingLockFlag = 1;
        } else {
          this.ruleForm.parkingLockFlag = 0;
        }
      },
      /**
       * 获取地图需要的城市名
       */
      changeDistrict(city) {
        const arr1 = [];
        city.forEach(item => {
          arr1.push(item.name);
        });
        this.addressName = arr1.join('');
        this.ruleForm.provinceId = city[0].id;
        this.ruleForm.cityId = city[1].id;
        this.ruleForm.districtId = city[2].id;
      },
      /**
       * 地图定位按钮
       */
      clickAddress() {
        // 判断省市区
        this.$refs.ruleForm0.validateField('addressId', errId => {
          // 判断详细地址
          this.$refs.ruleForm0.validateField('detailAddress', errAdd => {
            if (!errId && !errAdd) {
              if (this.id) {
                this.$refs.ykcMap.setMarker(); // 设置坐标  编辑第一次点击定位按钮，不定位
              } else {
                this.$nextTick(() => {
                  this.$refs.ykcMap.parseAddress(
                    this.addressName,
                    this.ruleForm.detailAddress,
                    obj => {
                      this.ruleForm.longitude = obj.lng; // 经度
                      this.ruleForm.latitude = obj.lat; // 纬度
                    }
                  );
                });
              }
            }
          });
        });
      },
      /**
       * 手动校中点位
       */
      mapClick(lngLat) {
        this.ruleForm.longitude = lngLat.lng;
        this.ruleForm.latitude = lngLat.lat;
      },
      /**
       * @desc  图片上传
       */
      uploadImg(file, fileList, isCheck, txt) {
        let locationIndex = null;
        switch (txt) {
          case '主入口图':
            locationIndex = 1;
            break;
          case '标志路径':
            locationIndex = 2;
            break;
          case '电站全景':
            locationIndex = 3;
            break;
          case '电桩特写':
            locationIndex = 4;
            break;
          case '其他图片':
            locationIndex = 5;
            break;
          default:
            break;
        }
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.stationImageList.push({
              url: res,
              locationIndex,
            });
          });
        }
      },
      /**
       * @desc 图片删除
       * */
      handleRemoveLogo(file) {
        this.ruleForm.stationImageList.splice(
          this.ruleForm.stationImageList.findIndex(item => item.url === file.url),
          1
        );
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm()
            .then(() => {
              if (!this.id) {
                stationPage
                  .dirAdd(this.formData)
                  .then(res => {
                    console.log('res++++新增', res);
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              } else {
                stationPage
                  .dirUpdate(this.formData)
                  .then(res => {
                    console.log('res++++编辑', res);
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              }
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * @desc 校验当前表格是否通过
       * */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              if (this.ruleForm.latitude !== null && this.ruleForm.longitude !== null) {
                resolve();
              } else {
                this.$message({
                  message: '请先点击地图定位按钮获取当前坐标!',
                  type: 'warning',
                });
              }
            } else {
              reject();
            }
          });
        });
      },
      /**
       * @desc 校验全部表格是否通过
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('err++++', err);
              reject();
            });
        });
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ operatorType: '1' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .directConnectionStation-detail {
    .upload-img-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .el-steps {
      margin: 20px 0;
    }
    ::v-deep .el-step__head.is-success {
      color: rgb(52, 158, 250);
      border-color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__title.is-success {
      font-weight: bold;
      color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__description.is-success {
      color: rgb(52, 158, 250);
    }
  }
</style>
