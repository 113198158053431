// const homePagePath = '/InvoicesManageList';

const EmptyLayout = {
  name: 'EmptyLayout',
  render() {
    return <router-view></router-view>;
  },
};

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../views/Login.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  电站资产 电站管理 直联电站
      {
        path: 'plantAssets/powerStationManagement/directConnectionStation/List',
        name: 'directConnectionStationList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionStationList' */ '../views/plantAssets/powerStationManagement/directConnectionStation/List.vue'
          ),
        meta: {
          pageTitle: '直联电站',
        },
      },
      //  电站资产 电站管理 直联电站详情
      {
        path: 'plantAssets/powerStationManagement/directConnectionStation/Detail',
        name: 'directConnectionStationDetail',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionStationDetail' */ '../views/plantAssets/powerStationManagement/directConnectionStation/Detail.vue'
          ),
        meta: {
          pageTitle: '直联电站详情',
        },
      },
      //  电站资产 电站管理 互联电站
      {
        path: 'plantAssets/powerStationManagement/interconnectionStation/List',
        name: 'interconnectionStationList',
        component: () =>
          import(
            /* webpackChunkName: 'interconnectionStationList' */ '../views/plantAssets/powerStationManagement/interconnectionStation/List.vue'
          ),
        meta: {
          pageTitle: '互联电站',
        },
      },
      //  电站资产 电站管理 互联电站详情
      {
        path: 'plantAssets/powerStationManagement/interconnectionStation/Detail',
        name: 'interconnectionStationDetail2',
        component: () =>
          import(
            /* webpackChunkName: 'interconnectionStationDetail' */ '../views/plantAssets/powerStationManagement/interconnectionStation/Detail.vue'
          ),
        meta: {
          pageTitle: '互联电站详情',
        },
      },
      //  电站资产 电桩管理 直联电桩
      {
        path: 'plantAssets/powerPileManagement/directConnectionPile/List',
        name: 'directConnectionPileList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionPileList' */ '../views/plantAssets/powerPileManagement/directConnectionPile/List.vue'
          ),
        meta: {
          pageTitle: '直联电桩',
        },
      },
      //  电站资产 电桩管理 互联电桩
      {
        path: 'plantAssets/powerPileManagement/interconnectionPile/List',
        name: 'interconnectionPileList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionPileList' */ '../views/plantAssets/powerPileManagement/interconnectionPile/List.vue'
          ),
        meta: {
          pageTitle: '互联电桩',
        },
      },
      //  电站资产 有序管理 有序台区管理
      {
        path: 'plantAssets/orderlyManagement/List',
        name: 'orderlyManagementList',
        component: () =>
          import(
            /* webpackChunkName: 'customerAccountsDetail' */ '../views/plantAssets/orderlyManagement/List.vue'
          ),
        meta: {
          pageTitle: '有序管理',
        },
      },
      //  电站资产 道闸管理 道闸管理列表
      {
        path: 'plantAssets/gateManagement/List',
        name: 'gateManagementList',
        component: () =>
          import(
            /* webpackChunkName: 'gateManagementList' */ '../views/plantAssets/gateManagement/List.vue'
          ),
        meta: {
          pageTitle: '道闸管理',
        },
      },
      //  电站资产 道闸管理 道闸管理详情
      {
        path: 'plantAssets/gateManagement/Detail',
        name: 'gateManagementDetail',
        meta: {
          pageTitle: '道闸管理详情',
        },
        component: () =>
          import(
            /* webpackChunkName: 'gateManagementDetail' */ '../views/plantAssets/gateManagement/Detail.vue'
          ),
      },
      //  电站资产 品牌型号 品牌型号
      {
        path: 'plantAssets/brandAndModel/List',
        name: 'brandAndModelList',
        component: () =>
          import(
            /* webpackChunkName: 'brandAndModelList' */ '../views/plantAssets/brandAndModel/List.vue'
          ),
        meta: {
          pageTitle: '品牌型号',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  订单管理 充电订单 实时订单
      {
        path: 'orderManagement/realTimeOrder/List',
        name: 'realTimeOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderList' */ '../views/orderManagement/realTimeOrder/List.vue'
          ),
        meta: {
          pageTitle: '实时订单',
        },
      },
      //  订单管理 充电订单 实时订单详情
      {
        path: 'orderManagement/realTimeOrder/Detail',
        name: 'realTimeOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderDetail' */ '../views/orderManagement/realTimeOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '实时订单详情',
        },
      },
      //  订单管理 充电订单 历史订单
      {
        path: 'orderManagement/historicalOrder/List',
        name: 'historicalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderList' */ '../views/orderManagement/historicalOrder/List.vue'
          ),
        meta: {
          pageTitle: '历史订单',
        },
      },
      //  订单管理 充电订单 历史订单详情
      {
        path: 'orderManagement/historicalOrder/Detail',
        name: 'historicalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderDetail' */ '../views/orderManagement/historicalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '历史订单详情',
        },
      },
      //  订单管理 充电订单 异常订单
      {
        path: 'orderManagement/abnormalOrder/List',
        name: 'abnormalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderList' */ '../views/orderManagement/abnormalOrder/List.vue'
          ),
        meta: {
          pageTitle: '异常订单',
        },
      },
      //  订单管理 充电订单 异常订单详情
      {
        path: 'orderManagement/abnormalOrder/Detail',
        name: 'abnormalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderDetail' */ '../views/orderManagement/abnormalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '异常订单详情',
        },
      },
      //  订单管理 占位订单 占位订单
      {
        path: 'orderManagement/placeholderOrder/List',
        name: 'placeholderOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'placeholderOrderList' */ '../views/orderManagement/placeholderOrder/List.vue'
          ),
        meta: {
          pageTitle: '占位订单',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      // 数据统计 平台统计 充电趋势统计
      {
        path: 'dataStatistics/platformStatistics/chargingTrendStatistics/List',
        name: 'chargingTrendStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'chargingTrendStatisticsList' */ '../views/dataStatistics/platformStatistics/chargingTrendStatistics/List.vue'
          ),
        meta: {
          pageTitle: '充电趋势统计',
        },
      },
      // 数据统计 平台统计 商户充电统计
      {
        path: 'dataStatistics/platformStatistics/merchanChargingStatistics/List',
        name: 'merchanChargingStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchanChargingStatisticsList' */ '../views/dataStatistics/platformStatistics/merchanChargingStatistics/List.vue'
          ),
        meta: {
          pageTitle: '商户充电统计',
        },
      },
      // 数据统计 平台统计 电站充电统计
      {
        path: 'dataStatistics/platformStatistics/stationChargingStatistics/List',
        name: 'stationChargingStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchanChargingStatisticsList' */ '../views/dataStatistics/platformStatistics/stationChargingStatistics/List.vue'
          ),
        meta: {
          pageTitle: '电站充电统计',
        },
      },
      // 数据统计 商户统计 充电趋势统计
      {
        path: 'dataStatistics/merchantStatistics/List',
        name: 'merchantStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantStatisticsList' */ '../views/dataStatistics/merchantStatistics/List.vue'
          ),
        meta: {
          pageTitle: '充电趋势统计',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  财务管理 账号管理 C端司机账号
      {
        path: 'financialManagement/accountManagement/cSideDriverAccounts',
        name: 'CSideDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccounts' */ '../views/financialManagement/accountManagement/cSideDriverAccount/CSideDriverAccounts.vue'
          ),
        meta: {
          pageTitle: 'C端司机账户',
        },
      },
      //  财务管理 账号管理 C端司机账号
      {
        path: 'financialManagement/accountManagement/cSideDriverAccountsDetail',
        name: 'CSideDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccountsDetail' */ '../views/financialManagement/accountManagement/cSideDriverAccount/CSideDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: 'C端司机账户',
        },
      },
      // C端充值退款记录
      {
        path: 'financialManagement/accountManagement/rechargeAndRefundRecords',
        name: 'RechargeAndRefundRecords',
        component: () =>
          import(
            /* webpackChunkName: 'RechargeAndRefundRecords' */ '../views/financialManagement/accountManagement/rechargeAndRefundRecords/List.vue'
          ),
        meta: {
          pageTitle: '充值退款记录',
        },
      },
      //  财务管理 账号管理 第三方支付账户
      {
        path: 'financialManagement/accountManagement/thirdPartyPaymentAccounts',
        name: 'ThirdPartyPaymentAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccounts' */ '../views/financialManagement/accountManagement/cSideDriverAccount/ThirdPartyPaymentAccounts.vue'
          ),
        meta: {
          pageTitle: '第三方支付账户',
        },
      },
      //  财务管理 账号管理 客户司机账户
      {
        path: 'financialManagement/customerAccount/customDriverAccounts',
        name: 'CustomDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CustomDriverAccounts' */ '../views/financialManagement/accountManagement/customerAccount/CustomDriverAccounts.vue'
          ),
        meta: {
          pageTitle: '司机账户',
        },
      },
      //  财务管理 账号管理 客户司机账户详情
      {
        path: 'financialManagement/customerAccount/customDriverAccountsDetail',
        name: 'CustomDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomDriverAccountsDetail' */ '../views/financialManagement/accountManagement/customerAccount/CustomDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '司机账户详情',
        },
      },
      //  财务管理 账号管理 客户账户
      {
        path: 'financialManagement/customerAccount/customerAccounts',
        name: 'CustomerAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerAccounts' */ '../views/financialManagement/accountManagement/customerAccount/CustomerAccounts.vue'
          ),
        meta: {
          pageTitle: '客户账户',
        },
      },
      //  财务管理 账号管理 客户账户详情
      {
        path: 'financialManagement/customerAccount/customerAccountsDetail',
        name: 'CustomerAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerAccountsDetail' */ '../views/financialManagement/accountManagement/customerAccount/CustomerAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '客户账户',
        },
      },
      //  财务管理 账号管理 子公司账户 内部客户账户
      {
        path: 'financialManagement/subsidiaryAccount/internalCustomerAccounts',
        name: 'InternalCustomerAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'InternalCustomerAccounts' */ '../views/financialManagement/accountManagement/subsidiaryAccount/InternalCustomerAccounts.vue'
          ),
        meta: {
          pageTitle: '内部客户账户',
        },
      },
      //  财务管理 账号管理 子公司账户 内部客户账户详情
      {
        path: 'financialManagement/subsidiaryAccount/internalCustomerAccountsDetail',
        name: 'InternalCustomerAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InternalCustomerAccountsDetail' */ '../views/financialManagement/accountManagement/subsidiaryAccount/InternalCustomerAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '内部客户账户详情',
        },
      },
      //  财务管理 账号管理 子公司账户 子公司司机账户
      {
        path: 'financialManagement/subsidiaryAccount/subDriverAccounts',
        name: 'SubDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'SubDriverAccounts' */ '../views/financialManagement/accountManagement/subsidiaryAccount/SubDriverAccounts.vue'
          ),
        meta: {
          pageTitle: '子公司司机账户',
        },
      },
      //  财务管理 账号管理 子公司账户 子公司司机账户详情
      {
        path: 'financialManagement/subsidiaryAccount/subDriverAccountsDetail',
        name: 'SubDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'SubDriverAccountsDetail' */ '../views/financialManagement/accountManagement/subsidiaryAccount/SubDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '子公司司机账户详情',
        },
      },
      //  财务管理 账号管理 互联互通账户 互联互通账户
      {
        path: 'financialManagement/interconnectedAccount/interconnectedAccounts',
        name: 'InterconnectedAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedAccounts' */ '../views/financialManagement/accountManagement/interconnectedAccount/InterconnectedAccounts.vue'
          ),
        meta: {
          pageTitle: '互联互通账户',
        },
      },
      //  财务管理 账号管理 互联互通账户 互联互通账户详情
      {
        path: 'financialManagement/interconnectedAccount/interconnectedAccountsDetail',
        name: 'InterconnectedAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedAccountsDetail' */ '../views/financialManagement/accountManagement/interconnectedAccount/InterconnectedAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '互联互通账户详情',
        },
      },
      //  财务管理 对账管理 平台收支账单
      {
        path: 'financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatements',
        name: 'PlatformReceiptsPaymentsStatements',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformReceiptsPaymentsStatements' */ '../views/financialManagement/accountReconciliationManagement/platformReceiptsPaymentsStatement/PlatformReceiptsPaymentsStatements.vue'
          ),
        meta: {
          pageTitle: '平台收支账单',
        },
      },
      //  财务管理 对账管理 平台收支账单明细
      {
        path: 'financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatementsDetail',
        name: 'PlatformReceiptsPaymentsStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformReceiptsPaymentsStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/platformReceiptsPaymentsStatement/PlatformReceiptsPaymentsStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '平台收支账单明细',
        },
      },
      //  财务管理 对账管理 互联互通账单
      {
        path: 'financialManagement/interconnectedBillingStatement/interconnectedBillingStatements',
        name: 'InterconnectedBillingStatements',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedBillingStatements' */ '../views/financialManagement/accountReconciliationManagement/interconnectedBillingStatement/InterconnectedBillingStatements.vue'
          ),
        meta: {
          pageTitle: '互联互通账单',
        },
      },
      //  财务管理 对账管理 互联互通账单明细
      {
        path: 'financialManagement/interconnectedBillingStatement/interconnectedBillingStatementsDetail',
        name: 'InterconnectedBillingStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedBillingStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/interconnectedBillingStatement/InterconnectedBillingStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '互联互通账单明细',
        },
      },
      //  财务管理 对账管理 商户账单
      {
        path: 'financialManagement/merchantStatement/merchantStatements',
        name: 'MerchantStatements',
        component: () =>
          import(
            /* webpackChunkName: 'MerchantStatements' */ '../views/financialManagement/accountReconciliationManagement/merchantStatement/MerchantStatements.vue'
          ),
        meta: {
          pageTitle: '商户账单',
        },
      },
      //  财务管理 对账管理 商户账单明细
      {
        path: 'financialManagement/merchantStatement/merchantStatementsDetail',
        name: 'MerchantStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'MerchantStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/merchantStatement/MerchantStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '商户账单明细',
        },
      },
      //  财务管理 对账管理 平台资金流账单
      {
        path: 'financialManagement/platformFundFlowStatement/platformFundFlowStatements',
        name: 'PlatformFundFlowStatements',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformFundFlowStatements' */ '../views/financialManagement/accountReconciliationManagement/platformFundFlowStatement/PlatformFundFlowStatements.vue'
          ),
        meta: {
          pageTitle: '平台资金流账单',
        },
      },
      //  财务管理 对账管理 占位费收支账单
      {
        path: 'financialManagement/occupancyFeeReceiptsPayments/occupancyFeeReceiptsPayments',
        name: 'OccupancyFeeReceiptsPayments',
        component: () =>
          import(
            /* webpackChunkName: 'OccupancyFeeReceiptsPayments' */ '../views/financialManagement/accountReconciliationManagement/occupancyFeeReceiptsPayments/OccupancyFeeReceiptsPayments.vue'
          ),
        meta: {
          pageTitle: '占位费收支账单',
        },
      },
      //  财务管理 对账管理 占位费收支账单详情
      {
        path: 'financialManagement/occupancyFeeReceiptsPayments/occupancyFeeReceiptsPaymentsDetail',
        name: 'OccupancyFeeReceiptsPaymentsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'OccupancyFeeReceiptsPaymentsDetail' */ '../views/financialManagement/accountReconciliationManagement/occupancyFeeReceiptsPayments/OccupancyFeeReceiptsPaymentsDetail.vue'
          ),
        meta: {
          pageTitle: '占位费收支账单明细',
        },
      },
      //  财务管理 对账管理 客户账单
      {
        path: 'financialManagement/customerStatement/customerStatements',
        name: 'CustomerStatements',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerStatements' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/CustomerStatements.vue'
          ),
        meta: {
          pageTitle: '客户账单',
        },
      },
      //  财务管理 对账管理 客户账单 客户账单明细
      {
        path: 'financialManagement/customerStatement/customerStatementsDetail',
        name: 'CustomerStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/CustomerStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '客户账单明细',
        },
      },
      //  财务管理 对账管理 客户账单 子机构账单
      {
        path: 'financialManagement/subAgencyBill/subAgencyBill',
        name: 'SubAgencyBill',
        component: () =>
          import(
            /* webpackChunkName: 'SubAgencyBill' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/subAgencyBill/SubAgencyBill.vue'
          ),
        meta: {
          pageTitle: '子机构账单',
        },
      },
      //  财务管理 对账管理 客户账单 子机构账单明细
      {
        path: 'financialManagement/subAgencyBill/subAgencyBillDetail',
        name: 'SubAgencyBillDetail',
        component: () =>
          import(
            /* webpackChunkName: 'SubAgencyBillDetail' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/subAgencyBill/SubAgencyBillDetail.vue'
          ),
        meta: {
          pageTitle: '子机构账单明细',
        },
      },
      //  财务管理 发票管理 发票管理
      {
        path: 'financialManagement/invoiceManagement/invoiceManagements',
        name: 'InvoiceManagements',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceManagements' */ '../views/financialManagement/invoiceManagement/InvoiceManagements.vue'
          ),
        meta: {
          pageTitle: '发票管理',
        },
      },
      //  财务管理 发票管理 开票处理
      {
        path: 'financialManagement/invoiceManagement/invoiceIssuingProcessing',
        name: 'InvoiceIssuingProcessing',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceIssuingProcessing' */ '../views/financialManagement/invoiceManagement/InvoiceIssuingProcessing.vue'
          ),
        meta: {
          pageTitle: '开票处理',
        },
      },
      //  财务管理 发票管理 开票处理
      {
        path: 'financialManagement/invoiceManagement/invoiceIssuingProcessing',
        name: 'InvoiceIssuingProcessing',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceIssuingProcessing' */ '../views/financialManagement/invoiceManagement/InvoiceIssuingProcessing.vue'
          ),
        meta: {
          pageTitle: '开票处理',
        },
      },

      //  财务管理 发票管理 已开票
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsIssued',
        name: 'InvoiceDetailsIssued',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsIssued' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsIssued.vue'
          ),
        meta: {
          pageTitle: '发票管理-已开票',
        },
      },
      //  财务管理 发票管理 已退回
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsReturned',
        name: 'InvoiceDetailsReturned',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsReturned' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsReturned.vue'
          ),
        meta: {
          pageTitle: '发票管理-已退回',
        },
      },
      //  财务管理 发票管理 待开票
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsToBeIssued',
        name: 'InvoiceDetailsToBeIssued',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsToBeIssued' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsToBeIssued.vue'
          ),
        meta: {
          pageTitle: '发票管理-待开票',
        },
      },
      //  财务管理 发票管理 已驳回
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsRejected',
        name: 'InvoiceDetailsRejected',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsRejected' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsRejected.vue'
          ),
        meta: {
          pageTitle: '发票管理-已驳回',
        },
      },
      //  系统设置 系统操作日志 操作日志
      {
        path: 'systemSettings/operationLog/operationLog',
        name: 'operationLog',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/systemSettings/operationLog/List.vue'
          ),
        meta: {
          pageTitle: '操作日志',
        },
      },
      //  系统设置 权限管理 账号管理
      {
        path: 'systemSettings/powerManage/accountManage',
        name: 'accountManage',
        component: () =>
          import(
            /* webpackChunkName: 'accountManage' */ '../views/systemSettings/powerManage/accountManage/List.vue'
          ),
        meta: {
          pageTitle: '账号管理',
        },
      },
      //  系统设置 权限管理 账号管理 账号详情
      {
        path: 'systemSettings/powerManage/accountManage/details',
        name: 'accountManageDetail',
        component: () =>
          import(
            /* webpackChunkName: 'accountManage' */ '../views/systemSettings/powerManage/accountManage/Detail.vue'
          ),
        meta: {
          pageTitle: '账号详情',
        },
      },
      //  系统设置 权限管理 角色管理
      {
        path: 'systemSettings/powerManage/roleManage',
        name: 'roleManage',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/powerManage/roleManage/List.vue'
          ),
        meta: {
          pageTitle: '角色管理',
        },
      },
      //  系统设置 权限管理 角色管理 角色详情
      {
        path: 'systemSettings/powerManage/roleManage/details',
        name: 'roleManageDetail',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/powerManage/roleManage/Detail.vue'
          ),
        meta: {
          pageTitle: '角色详情',
        },
      },
      //  系统设置 系统配置 系统配置
      {
        path: 'systemSettings/systemConfig/systemConfig',
        name: 'systemConfigList',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/systemConfig/systemConfig/List.vue'
          ),
        meta: {
          pageTitle: '系统配置',
        },
      },
      //  系统设置 系统消息 消息管理
      {
        path: 'systemSettings/systemNotice/noticeManage',
        name: 'noticeManageList',
        component: () =>
          import(
            /* webpackChunkName: 'noticeManage' */ '../views/systemSettings/systemNotice/noticeManage/List.vue'
          ),
        meta: {
          pageTitle: '消息管理',
        },
      },
      //  系统设置 下载中心 下载列表
      {
        path: 'systemSettings/downloadCenter',
        name: 'downloadCenterList',
        component: () =>
          import(
            /* webpackChunkName: 'noticeManage' */ '../views/systemSettings/downloadCenter/List.vue'
          ),
        meta: {
          pageTitle: '下载中心',
        },
      },
      //  系统设置 版本更新
      {
        path: 'systemSettings/upgradeManage',
        name: 'upgradeManage',
        component: () =>
          import(
            /* webpackChunkName: 'upgradeManage' */ '../views/systemSettings/upgradeManage/List.vue'
          ),
        meta: {
          pageTitle: '版本更新',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  营销中心 用户端广告 广告列表
      {
        path: '/marketingCenter/clientAds/List',
        name: 'clientAdsList',
        component: () => import('../views/marketingCenter/clientAds/List.vue'),
        meta: {
          pageTitle: '用户端广告列表',
        },
      },
      //  营销中心 广告管理 用户端广告 广告编辑
      {
        path: '/marketingCenter/clientAds/Detail',
        name: 'clientAdsDetail',
        component: () => import('../views/marketingCenter/clientAds/Detail.vue'),
        meta: {
          pageTitle: '用户端广告详情',
        },
      },
      //  营销中心 标签分组 用户分组 列表
      {
        path: '/marketingCenter/labelUsers/List',
        name: 'labelUsersList',
        component: () => import('../views/marketingCenter/labelUsers/List.vue'),
        meta: {
          pageTitle: '用户分组列表',
        },
      },
      //  营销中心 标签分组 用户分组 详情
      {
        path: '/marketingCenter/labelUsers/Detail',
        name: 'labelUsersDetail',
        component: () => import('../views/marketingCenter/labelUsers/Detail.vue'),
        meta: {
          pageTitle: '用户分组详情',
        },
      },
      //  营销中心 标签分组 电站分组 列表
      {
        path: '/marketingCenter/labelStations/List',
        name: 'labelStationsList',
        component: () => import('../views/marketingCenter/labelStations/List.vue'),
        meta: {
          pageTitle: '电站分组列表',
        },
      },
      //  营销中心 标签分组 电站分组 编辑
      {
        path: '/marketingCenter/labelStations/Detail',
        name: 'labelStationsDetail',
        component: () => import('../views/marketingCenter/labelStations/Detail.vue'),
        meta: {
          pageTitle: '电站分组详情',
        },
      },

      /** ############################################ 运营中心 ############################################## */
      {
        path: '/operationCenter/priceManage/chargingPriceStrategy',
        name: 'chargingPriceStrategyList',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceStrategy/List.vue'),
        meta: {
          pageTitle: '充电价格策略',
        },
      },
      {
        path: '/operationCenter/priceManage/chargingPriceStrategy/details',
        name: 'chargingPriceStrategyDetail',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceStrategy/Detail.vue'),
        meta: {
          pageTitle: '充电价格策略详情',
        },
      },
      {
        path: '/operationCenter/priceManage/occupyingPriceStrategy',
        name: 'occupyingPriceStrategyList',
        component: () =>
          import('../views/operationCenter/priceManage/occupyingPriceStrategy/List.vue'),
        meta: {
          pageTitle: '占位价格策略',
        },
      },
      {
        path: '/operationCenter/priceManage/occupyingPriceStrategy/details',
        name: 'occupyingPriceStrategyDetail',
        component: () =>
          import('../views/operationCenter/priceManage/occupyingPriceStrategy/Detail.vue'),
        meta: {
          pageTitle: '占位价格策略详情',
        },
      },
      {
        path: '/operationCenter/priceManage/royaltyStrategy',
        name: 'royaltyStrategyList',
        component: () => import('../views/operationCenter/priceManage/royaltyStrategy/List.vue'),
        meta: {
          pageTitle: '抽成费用策略',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage',
        name: 'vipManageList',
        component: () => import('../views/operationCenter/vipManage/vipManage/List.vue'),
        meta: {
          pageTitle: '会员管理',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/details',
        name: 'vipManageDetail',
        component: () => import('../views/operationCenter/vipManage/vipManage/Detail.vue'),
        meta: {
          pageTitle: '会员详情',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/integralFlow',
        name: 'integralFlow',
        component: () => import('../views/operationCenter/vipManage/vipManage/IntegralFlow.vue'),
        meta: {
          pageTitle: '积分流水',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/growthValueFlow',
        name: 'growthValueFlow',
        component: () => import('../views/operationCenter/vipManage/vipManage/GrowthValueFlow.vue'),
        meta: {
          pageTitle: '成长值流水',
        },
      },
      {
        path: '/operationCenter/vipManage/vipLevel',
        name: 'vipLevelList',
        component: () => import('../views/operationCenter/vipManage/vipLevel/List.vue'),
        meta: {
          pageTitle: '会员等级配置',
        },
      },
      {
        path: '/operationCenter/operationConfig/siteOperationConfig',
        name: 'siteOperationConfigList',
        component: () =>
          import('../views/operationCenter/operationConfig/siteOperationConfig/List.vue'),
        meta: {
          pageTitle: '站点运营配置',
        },
      },
      // {
      //   path: '/operationCenter/operationConfig/siteOperationConfig/details',
      //   name: 'siteOperationConfigDetail',
      //   component: () =>
      //     import('../views/operationCenter/operationConfig/siteOperationConfig/Detail.vue'),
      //   meta: {
      //     pageTitle: '会员详情',
      //   },
      // },
      {
        path: '/operationCenter/operationConfig/userBlackList',
        name: 'userBlackList',
        component: () => import('../views/operationCenter/operationConfig/userBlackList/List.vue'),
        meta: {
          pageTitle: '用户黑名单',
        },
      },
      {
        path: '/operationCenter/operationConfig/userBlackList/details',
        name: 'userBlackListDetail',
        component: () =>
          import('../views/operationCenter/operationConfig/userBlackList/Detail.vue'),
        meta: {
          pageTitle: '用户黑名单详情',
        },
      },
      {
        path: '/operationCenter/operationConfig/availableSitesConfig',
        name: 'availableSitesConfigList',
        component: () =>
          import('../views/operationCenter/operationConfig/availableSitesConfig/List.vue'),
        meta: {
          pageTitle: '可用站点配置',
        },
      },
      {
        path: '/operationCenter/operationConfig/availableSitesConfig/details',
        name: 'availableSitesConfigDetail',
        component: () =>
          import('../views/operationCenter/operationConfig/availableSitesConfig/Detail.vue'),
        meta: {
          pageTitle: '可用站点配置详情',
        },
      },
    ],
  },
  {
    // 营销管理start
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  1.实体充电卡
      {
        path: '/marketingCenter/chargingCard/chargingCardManage',
        name: 'chargingCardManageList',
        component: () => import('../views/marketingCenter/chargingCard/chargingCardManage/List'),
        meta: {
          pageTitle: '充电卡管理',
        },
      },
      {
        path: '/marketingCenter/chargingCard/offlineCardManage',
        name: 'offlineCardManageList',
        component: () => import('../views/marketingCenter/chargingCard/offlineCardManage/List'),
        meta: {
          pageTitle: '离线卡管理',
        },
      },
      {
        path: '/marketingCenter/chargingCard/offlineCardManage/detail',
        name: 'offlineCardManageDetail',
        component: () => import('../views/marketingCenter/chargingCard/offlineCardManage/Detail'),
        meta: {
          pageTitle: '离线卡详情',
        },
      },

      //  2.平台营销工具
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity',
        name: 'platformPriceActivityList',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/List'
          ),
        meta: {
          pageTitle: '平台价格活动',
        },
      },

      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/details',
        name: 'platformPriceActivityDetail',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/Detail'
          ),
        meta: {
          pageTitle: '平台价格活动详情',
        },
      },
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity',
        name: 'platformCouponActivityList',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/List'
          ),
        meta: {
          pageTitle: '平台卡券活动',
        },
      },
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/pickUpRecord',
        name: 'platformCouponActivityPickUpRecord',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/PickUpRecord'
          ),
        meta: {
          pageTitle: '平台卡券活动',
        },
      },
      {
        path: '/marketingCenter/details',
        name: 'detail',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/Detail'
          ),
        meta: {
          pageTitle: '平台卡券活动详情',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 优惠券管理
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon',
        name: 'platformCouponList',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/List'
          ),
        meta: {
          pageTitle: '优惠券管理',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 优惠券管理 优惠券明细
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon/particulars',
        name: 'platformCouponParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/Particulars'
          ),
        meta: {
          pageTitle: '优惠券明细',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 优惠券管理 优惠券详情
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon/details',
        name: 'platformCouponDetail',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/Detail'
          ),
        meta: {
          pageTitle: '优惠券详情',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 抵用卡管理
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard',
        name: 'platformCreditCardList',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/List'
          ),
        meta: {
          pageTitle: '抵用卡管理',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 抵用卡管理 抵用卡明细
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/particulars',
        name: 'platformCreditCardParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/Particulars'
          ),
        meta: {
          pageTitle: '抵用卡明细',
        },
      },
      // 营销中心 平台营销工具 平台卡券管理 抵用卡管理 抵用卡详情
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/details',
        name: 'platformCreditCardDetail',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/Detail'
          ),
        meta: {
          pageTitle: '抵用卡详情',
        },
      },
      // 营销中心 平台营销工具 积分商城 商品管理
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/commodity',
        name: 'commodityList',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/commodity/List'),
        meta: {
          pageTitle: '商品管理',
        },
      },
      // 营销中心 平台营销工具 积分商城 商品管理 商品详情
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/commodity/details',
        name: 'commodityDetail',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/commodity/Detail'),
        meta: {
          pageTitle: '商品管理',
        },
      },
      // 营销中心 平台营销工具 积分商城 兑换管理
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/exchange',
        name: 'exchangeList',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/exchange/List'),
        meta: {
          pageTitle: '兑换管理',
        },
      },
      // 营销中心 平台营销工具 积分商城 兑换管理 兑换详情
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/exchange/details',
        name: 'exchangeDetail',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/exchange/Detail'),
        meta: {
          pageTitle: '兑换详情',
        },
      },
      //  3.商户营销工具
      // 营销中心 商户营销工具 营销活动 商户价格活动
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity',
        name: 'merchantPriceActivityList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/List'
          ),
        meta: {
          pageTitle: '商户价格活动',
        },
      },
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/detail',
        name: 'merchantPriceActivityDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/Detail'
          ),
        meta: {
          pageTitle: '商户价格活动',
        },
      },
      // 营销中心 商户营销工具 营销活动 商户卡券活动
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity',
        name: 'merchantCouponActivityList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/List'
          ),
        meta: {
          pageTitle: '商户卡券活动',
        },
      },
      {
        path: '/marketingCenter/merchant/details',
        name: 'merchantCouponActivityDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/Detail'
          ),
        meta: {
          pageTitle: '商户卡券活动详情',
        },
      },
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/pickUpRecord',
        name: 'merchantCouponActivityPickUpRecord',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/PickUpRecord'
          ),
        meta: {
          pageTitle: '商户卡券领取记录',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 优惠券管理
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon',
        name: 'merchantCouponList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/List'
          ),
        meta: {
          pageTitle: '优惠券管理',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 优惠券管理 优惠券明细
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/particulars',
        name: 'merchantCouponParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/Particulars'
          ),
        meta: {
          pageTitle: '优惠券明细',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 优惠券管理 优惠券详情
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/details',
        name: 'merchantCouponDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/Detail'
          ),
        meta: {
          pageTitle: '优惠券详情',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 抵用卡管理
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard',
        name: 'merchantCreditCardList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/List'
          ),
        meta: {
          pageTitle: '抵用卡管理',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 抵用卡管理 抵用卡明细
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/particulars',
        name: 'merchantCreditCardParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/Particulars'
          ),
        meta: {
          pageTitle: '抵用卡明细',
        },
      },
      // 营销中心 商户营销工具 商户卡券管理 抵用卡管理 抵用卡详情
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/details',
        name: 'merchantCreditCardDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/Detail'
          ),
        meta: {
          pageTitle: '抵用卡详情',
        },
      },
      // 4.广告管理
      // 5.标签分组
    ],
    // 营销管理end
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  司机管理 充电客户 客户机构列表
      {
        path: '/customerCenter/customerCharge/organization/organizationList',
        name: 'customerChargeList',
        component: () => import('../views/customerCenter/customerCharge/organization/List.vue'),
        meta: {
          pageTitle: '客户机构列表',
        },
      },
      //  司机管理 充电客户 客户司机列表
      {
        path: '/customerCenter/customerCharge/customerDriver/customerDriverList',
        name: 'customerDriverList',
        component: () => import('../views/customerCenter/customerCharge/customerDriver/List.vue'),
        meta: {
          pageTitle: '客户司机列表',
        },
      },
      //  司机管理 充电客户 客户司机详情
      {
        path: '/customerCenter/customerCharge/customerDriver/customerDriverDetail',
        name: 'customerDriverDetail',
        component: () => import('../views/customerCenter/customerCharge/customerDriver/Detail.vue'),
        meta: {
          pageTitle: '司机详情',
        },
      },
      //  司机管理 充电客户 客户车辆列表
      {
        path: '/customerCenter/customerCharge/customerVehicle/customerVehicleList',
        name: 'customerVehicleList',
        component: () => import('../views/customerCenter/customerCharge/customerVehicle/List.vue'),
        meta: {
          pageTitle: '客户车辆列表',
        },
      },
      //  司机管理 充电客户 C端司机列表
      {
        path: '/customerCenter/customerCustomer/customerCustomerList',
        name: 'customerCustomerList',
        component: () => import('../views/customerCenter/customerCustomer/List.vue'),
        meta: {
          pageTitle: 'C端司机列表',
        },
      },
      //  司机管理 充电客户 C端司机详情
      {
        path: '/customerCenter/customerCustomer/customerCustomerDetail',
        name: 'customerCustomerDetail',
        component: () => import('../views/customerCenter/customerCustomer/Detail.vue'),
        meta: {
          pageTitle: 'C端司机详情',
        },
      },
      //  司机管理 内部司机 子公司管理列表
      {
        path: '/customerCenter/internalDriver/subsidiaryCompany/subsidiaryCompanyList',
        name: 'subsidiaryCompanyList',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryCompany/List.vue'),
        meta: {
          pageTitle: '子公司列表',
        },
      },
      //  司机管理 内部司机 内部车辆列表
      {
        path: '/customerCenter/internalDriver/subsidiaryVehicle/subsidiaryVehicleList',
        name: 'subsidiaryVehicleList',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryVehicle/List.vue'),
        meta: {
          pageTitle: '内部车辆列表',
        },
      },
      //  司机管理 内部司机 子公司司机列表
      {
        path: '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverList',
        name: 'subsidiaryDriverList',
        component: () => import('../views/customerCenter/internalDriver/subsidiaryDriver/List.vue'),
        meta: {
          pageTitle: '子公司司机',
        },
      },
      //  司机管理 内部司机 子公司司机详情
      {
        path: '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverDetail',
        name: 'subsidiaryDriverDetail',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryDriver/Detail.vue'),
        meta: {
          pageTitle: '子公司司机详情',
        },
      },
      //  司机管理 互联互通客户 互联互通客户管理 列表
      {
        path: '/customerCenter/customerUnion/customerUnion/customerUnionList',
        name: 'customerUnionList',
        component: () => import('../views/customerCenter/customerUnion/customerUnion/List.vue'),
        meta: {
          pageTitle: '互联互通客户',
        },
      },
      //  司机管理 互联互通客户 互联互通客户管理 详情
      {
        path: '/customerCenter/customerUnion/customerUnion/customerUnionDetail',
        name: 'customerUnionDetail',
        component: () => import('../views/customerCenter/customerUnion/customerUnion/Detail.vue'),
        meta: {
          pageTitle: '互联互通客户详情',
        },
      },
      //  司机管理 互联互通客户 互联互通秘钥管理 详情
      {
        path: '/customerCenter/customerUnion/cipherUnion/cipherUnionList',
        name: 'cipherUnionList',
        component: () => import('../views/customerCenter/customerUnion/cipherUnion/List.vue'),
        meta: {
          pageTitle: '互联推送管理',
        },
      },
      //  司机管理 互联互通客户 互联互通秘钥管理 详情
      {
        path: '/customerCenter/customerUnion/cipherUnion/cipherUnionDetail',
        name: 'cipherUnionDetail',
        component: () => import('../views/customerCenter/customerUnion/cipherUnion/Detail.vue'),
        meta: {
          pageTitle: '互联推送详情',
        },
      },
    ],
  },
  {
    // 商户管理
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  商户管理 平台商户 商户列表
      {
        path: '/merchantCenter/merchantPlatform/List',
        name: 'platformMerchantList',
        component: () => import('../views/merchantCenter/merchantPlatform/List.vue'),
        meta: {
          pageTitle: '平台商户管理',
        },
      },
      //  商户管理 平台商户 商户详情
      {
        path: '/merchantCenter/merchantPlatform/Detail',
        name: 'platformMerchantDetail',
        component: () => import('../views/merchantCenter/merchantPlatform/Detail.vue'),
        meta: {
          pageTitle: '商户详情',
        },
      },
      //  商户管理 互联商户 商户列表
      {
        path: '/merchantCenter/merchantUnion/List',
        name: 'merchantUnionList',
        component: () => import('../views/merchantCenter/merchantUnion/List.vue'),
        meta: {
          pageTitle: '互联商户管理',
        },
      },
    ],
  },
  {
    // 运维管理
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  运维管理 告警信息 告警列表
      {
        path: '/operationManagement/alarmInfo/omAlarmsInfoList',
        name: 'omAlarmsInfoList',
        component: () => import('../views/operationManagement/alarmInfo/OMAlarmsInfoList.vue'),
        meta: {
          pageTitle: '告警列表',
        },
      },
      //  运维管理 设备运维 设备报文
      {
        path: '/operationManagement/deviceOM/deviceMessageList',
        name: 'deviceMessageList',
        component: () => import('../views/operationManagement/deviceOM/DeviceMessageList.vue'),
        meta: {
          pageTitle: '设备报文',
        },
      },
      //  运维管理 设备运维 远程升级
      {
        path: '/operationManagement/deviceOM/remoteUpgradeList',
        name: 'remoteUpgradeList',
        component: () => import('../views/operationManagement/deviceOM/RemoteUpgradeList.vue'),
        meta: {
          pageTitle: '远程升级',
        },
      },
      //  菜单管理 前端自用
      {
        path: '/menuSetting',
        name: 'menuSetting',
        component: () => import('../views/systemSettings/menuSetting/index.vue'),
        meta: {
          pageTitle: '菜单管理',
        },
      },
    ],
  },
  {
    path: '/',
    component: EmptyLayout,
    children: [
      // #region 大屏
      {
        path: 'overview/operation',
        name: 'OverviewOperation',
        component: () =>
          import(/* webpackChunkName: 'OverviewOperation' */ '@/views/overview/operation/Main.vue'),
        meta: {
          pageTitle: '运营大屏',
        },
      },
      {
        path: 'overview/station',
        name: 'OverviewStation',
        component: () =>
          import(/* webpackChunkName: 'OverviewStation' */ '@/views/overview/station/Main.vue'),
        meta: {
          pageTitle: '电站大屏',
        },
      },
      // #endregion
    ],
  },
  {
    path: '/NotFound',
    meta: {},
    component: () => import(/* webpackChunkName: 'baseModule' */ '../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/NotFound',
    // component: () => import(/* webpackChunkName: 'baseModule' */ '../views/NotFound.vue'),
  },
];
