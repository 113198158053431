<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="content">
      <YkcDetailPlus class="box-card" :labelWidth="80" v-if="formData.protocol === 2">
        <div slot="header" class="clearfix">
          <span class="title">{{ formData.name || '——' }}</span>
        </div>
        <YkcDetailItemPlus :span="12" label="场站编码:">
          {{ formData.gateCode || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="第三方账号:">
          {{ formData.thirdAccount || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="协议模式:">
          {{ formData.protocol === 1 ? 'server' : 'client' || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="应用编码:">
          {{ formData.applicationCode || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="归属电站:">
          {{ formData.stationName || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="秘钥:">
          {{ formData.secret || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="订单推送地址:">
          {{ formData.orderAddress || '——' }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="80" v-else>
        <div slot="header" class="clearfix">
          <span class="title">{{ formData.name || '——' }}</span>
        </div>
        <YkcDetailItemPlus :span="12" label="场站编码:">
          {{ formData.gateCode || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="归属电站:">
          {{ formData.stationName || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="协议模式:">
          {{ formData.protocol === 1 ? 'server' : 'client' || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="减免类型:">
          {{ formData.discountType === 1 ? '固定时长' : '充电时长' || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="秘钥:">
          {{ formData.secret || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="减免时长:">
          {{ formData.fixedTime || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="应用编码:">
          {{ formData.applicationCode || '——' }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="12" label="减免说明:">
          {{ formData.remark || '——' }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
    </div>
  </scroll-layout>
</template>

<script>
  import { barrierPage } from '@/service/apis';

  export default {
    name: 'gateManagementDetail',
    components: {},
    data() {
      return {
        formData: {},
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
          {
            text: '编辑',
            type: 'plain',
            handleClick: () => {
              this.$router.push({
                path: '/stationManage/stationClue/track',
                query: { id: this.$route.query.id },
              });
            },
          },
        ];
      },
    },
    created() {
      barrierPage.detail(this.$route.query).then(res => {
        this.formData = res;
      });
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .content {
    .box-card {
      margin: 10px 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      table {
        width: 100%;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
      // .title {
      //   padding-left: 15px;
      //   position: relative;
      //   &::before {
      //     content: ' ';
      //     position: absolute;
      //     width: 8px;
      //     height: 8px;
      //     left: 0;
      //     top: calc(50% - 4px);
      //     border-radius: 8px;
      //     background-color: var(--theme-color-primary);
      //   }
      // }
      // .title2 {
      //   margin-left: 10px;
      //   padding-left: 10px;
      //   position: relative;
      //   &::before {
      //     content: ' ';
      //     position: absolute;
      //     width: 3px;
      //     height: 100%;
      //     left: 0;
      //     background-color: var(--theme-color-primary);
      //   }
      // }
    }
  }
</style>
