<!--
* @Author: Yanxia Wan
* @Date: 2021-10-27
* @Description: 详情主体组件
-->
<template>
  <div class="ykc-detail-item-box">
    <el-form-item
      ref="ykcDetailItem"
      :class="formItemClass"
      :label="label"
      :label-width="labelWidth"
      :tagText="tagText">
      <slot></slot>
      <div class="tag" v-if="tagText" @click="onTagClick">
        {{ tagText }}
      </div>
    </el-form-item>
  </div>
</template>

<script>
  export default {
    name: 'YkcDetailItem',
    props: {
      label: String, // 标签文本
      labelWidth: String, // 12*字数+8
      formItemClass: String,
      isTag: {
        // 是否显示tag
        type: Boolean,
        default: false,
      },
      tagText: {
        // tag内容 默认为空
        type: String,
        default: '',
      },
    },
    methods: {
      onTagClick() {
        this.$emit('tagClick');
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
